import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

import { BehaviorSubject, Subject, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldAvailableModules } from '../custom-field.constants';
import {
  ICustomFieldServerResponseDetail,
  IDataType,
  IModules,
} from '../custom-field.types';
import { noWhitespaceValidator } from '../../shared/utility/CustomValidators';
import { environment } from '../../../environments/environment';

const MAX_OPTIONS_ALLOWED = 30;

interface CustomFieldDetailPayload {
  module: string;
  name: string;
  type: string;
  description: string;
  required: boolean;
  defaultValue: string;
  addToReceiptOption: boolean;
  options: string[];
}
interface IMainBuilderFormData {
  module: string;
  name: string;
  dataType: IDataType;
  required: boolean;
  printable: boolean;
}
@Injectable()
export class CustomFieldFormService {
  secondaryFormData$ = new BehaviorSubject<
  Partial<{
    options: { label: string; default: boolean }[];
    defaultOption: number;
    text: string;
    number: number;
    datetime: Date;
    switch: boolean;
    help: string;
  }>
  >({});

  detailLoading$ = new BehaviorSubject<boolean>(false);

  mainFormData$ = new BehaviorSubject<Partial<IMainBuilderFormData>>({});

  mainFormBuilderRef = this.formBuilderService.group({
    module: [CustomFieldAvailableModules[0].code, Validators.required],
    name: ['', [Validators.required, noWhitespaceValidator]],
    dataType: [null as IDataType, Validators.required],
    required: false,
    printable: false,
  });

  secondaryFormBuilderRef = this.formBuilderService.group({
    options: this.formBuilderService.array(
      [] as { label: string; default: boolean }[],
    ),
    defaultOption: null as number,
    text: '',
    number: null as number,
    datetime: null as Date,
    switch: false,
    help: '',
  });

  formChanges$ = new Subject();

  readonly customFieldURL: string = environment.customFieldApiPath;

  constructor(
    private formBuilderService: FormBuilder,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.mainFormBuilderRef.valueChanges.subscribe((data) => {
      this.mainFormData$.next(data);
    });
    this.secondaryFormBuilderRef.valueChanges.subscribe((data) => {
      this.secondaryFormData$.next(data as any);
    });
  }

  setTileType(tileType: IDataType) {
    this.mainFormBuilderRef.patchValue({ dataType: tileType });
  }

  createNewCustomField() {
    const payload = this.convertToPayload();
    return this.http.post(
      `${this.customFieldURL}/custom-field/create`,
      payload,
    );
  }

  setDefaultModule(module: IModules) {
    if (CustomFieldAvailableModules.map((x) => x.code).includes(module)) {
      this.mainFormBuilderRef.patchValue({ module });
    }
  }

  updateExistingCustomField(id: number) {
    const payload = this.convertToPayload();
    return this.http.put(`${this.customFieldURL}/custom-field/${id}`, payload);
  }

  convertToPayload(): CustomFieldDetailPayload {
    const mainData = this.mainFormBuilderRef.getRawValue();
    const secondaryData = this.secondaryFormBuilderRef.value;
    const type: IDataType = mainData.dataType as any;
    let defaultValue;
    switch (type) {
      case 'text':
      case 'number':
      case 'datetime':
      case 'switch':
        defaultValue = secondaryData[type] !== null ? `${secondaryData[type]}` : '';
        break;
      case 'single-select':
        defaultValue = secondaryData.defaultOption !== null
          ? `${secondaryData.options.length ? secondaryData.options[secondaryData.defaultOption].label : ''}`
          : '';
        break;
      case 'multi-select':
        defaultValue = `${secondaryData.options
          .map((option: any) => (option.default ? option.label : null))
          .filter((code) => code !== null)}`;
        break;
      default:
        throw Error('invalid data type');
    }

    return {
      // maindata related
      module: mainData.module,
      name: mainData.name.trim(),
      required: mainData.required || false,
      addToReceiptOption: mainData.printable || false,
      type,
      // secondary data related
      options: type.endsWith('-select')
        ? secondaryData.options.map((x: any) => x.label)
        : undefined,
      defaultValue,
      description: secondaryData.help || '',
    };
  }

  reset() {
    this.mainFormBuilderRef.reset({
      module: CustomFieldAvailableModules[0].code,
      printable: false,
      required: false,
    });
    this.secondaryFormBuilderRef.reset();
    const options = <FormArray> this.secondaryFormBuilderRef.controls.options;
    while (options.length) {
      options.removeAt(0);
    }
  }

  async loadDetail(id, handleError?: any) {
    this.detailLoading$.next(true);
    await new Promise<void>((resolve, reject) => {
      this.http.get(`${this.customFieldURL}/custom-field/${id}`).subscribe({
        next: (detail: ICustomFieldServerResponseDetail) => {
          if (!detail) {
            this.detailLoading$.next(false);
            return handleError(
              new Error(this.translateService.instant(`customFields.getError`)),
            );
          }
          const { type }: { type: IDataType } = detail;
          let optionsData = [];
          const optionsLabel = detail.options;
          if (type === 'multi-select') {
            const optionsDefault = new Set(detail.defaultValue.split(','));
            optionsData = optionsLabel.map((label) => this.formBuilderService.group({
              label,
              default:
                  detail.defaultValue === '' ? null : optionsDefault.has(label),
            }));
          }
          if (type === 'single-select') {
            optionsData = optionsLabel.map((label) => this.formBuilderService.group({
              label,
              default: false,
            }));
          }

          const options = this.formBuilderService.array(optionsData);

          this.mainFormBuilderRef.patchValue({
            module: detail.module as IModules,
            name: detail.name,
            dataType: type as IDataType,
            required: detail.required,
            printable: detail.addToReceiptOption,
          });
          this.mainFormBuilderRef.get('module').disable();
          let number = 0;
          if (type === 'number') {
            if (detail.defaultValue !== '' && detail.defaultValue !== 'NaN') {
              number = parseFloat(detail.defaultValue);
            } else number = null;
          }
          this.secondaryFormBuilderRef.patchValue({
            // options:options,
            defaultOption:
              type === 'single-select' && detail.defaultValue !== ''
                ? detail.options.findIndex((o) => o === detail.defaultValue)
                : null,
            text: type === 'text' ? detail.defaultValue : '',
            // eslint-disable-next-line no-nested-ternary
            number,
            datetime:
              // eslint-disable-next-line no-nested-ternary
              type === 'datetime'
                ? detail.defaultValue
                  ? new Date(detail.defaultValue)
                  : null
                : new Date(),
            switch: type === 'switch' ? detail.defaultValue === 'true' : false,
            help: detail.description,
          });
          if (type === 'single-select' || type === 'multi-select') {
            this.secondaryFormBuilderRef.setControl('options', options as any);
          }

          resolve();
        },
        error: () => {
          handleError?.();
        },
      });
    });
    this.detailLoading$.next(false);
  }

  public get options() {
    return this.secondaryFormBuilderRef.get('options') as FormArray;
  }

  public get defaultOption() {
    return this.secondaryFormBuilderRef.get('defaultOption') as FormControl;
  }

  public get selectedTileType$() {
    return this.mainFormData$.pipe(map((data) => data.dataType));
  }

  public get dropdownPreparedOptions$() {
    return this.secondaryFormData$.pipe(
      map((data) => {
        const options = data.options as {
          label: string;
          default: boolean;
        }[];
        if (!options) return [];
        return options.map((option, index) => ({
          name: option.label,
          code: index,
        }));
      }),
    );
  }

  public get dropdownMultiSelectValues$() {
    return this.secondaryFormData$.pipe(
      map((data) => {
        const options = data.options as {
          label: string;
          default: boolean;
        }[];
        if (!options) return [];
        return options
          .map((option, index) => (option.default ? index : null))
          .filter((code) => code !== null);
      }),
    );
  }

  removeOption(index: number) {
    this.options.removeAt(index);
    const currentValue = this.defaultOption.value;
    if (currentValue !== null) {
      if (this.defaultOption.value === index) {
        this.defaultOption.patchValue(null);
      } else if (currentValue > index) {
        this.defaultOption.patchValue(currentValue - 1);
      }
    }
  }

  addOption() {
    this.options.push(
      this.formBuilderService.group({
        label: ['', [Validators.required, noWhitespaceValidator]],
        default: false,
      }),
    );
  }

  public get maxOptions() {
    return MAX_OPTIONS_ALLOWED;
  }

  public get optionsLimitReached$() {
    return this.secondaryFormData$.pipe(
      map((data) => (data.options?.length ?? 0) >= this.maxOptions),
    );
  }
}
