import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { FirebaseNotificationService } from '../../shared/services/firebase.services/firebase-notification.service';
import { FirebaseUserService } from '../../shared/services/firebase.services/firebase-user.service';
import { FirebaseAuthenticationService } from '../../shared/services/firebase.services/firebase-authentication.service';
import { getErrorMessageKey as getCognitoErrorMessageKey } from '../../utils/CognitoTranslateDictionary';
import { AuthUser, LoginDetails, MerchantUser } from '../auth.models';
import { AuthService } from './auth.service';
import { MerchantService } from './merchant.service';
import { UserService } from './user.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { LocalStorageKey } from '../../shared/constants';
import { ReferralProgramService } from '../../referral-program/services/referral-program.service';
import { DeliveryMediumType } from '../../shared/types/auth.types';

@Injectable()
export class UserAuthenticationService {
  constructor(
    private authService: AuthService,
    private merchantService: MerchantService,
    private userService: UserService,
    private firebaseAuthenticationService: FirebaseAuthenticationService,
    private firebaseUserService: FirebaseUserService,
    private firebaseNotificationService: FirebaseNotificationService,
    private localStorageService: LocalStorageService,
    private referralProgramService: ReferralProgramService,
  ) {}

  public async login(details: LoginDetails) {
    await this.loginOnCognito(details);

    const merchantDetails = await this.getMerchantDetails();

    // check whether the user is reseller or not
    const isReseller = await firstValueFrom(
      this.referralProgramService.findUserIsReseller(),
    );
    // eslint-disable-next-line @typescript-eslint/dot-notation
    merchantDetails['isReseller'] = isReseller;
    this.userService.setUser(merchantDetails as AuthUser);

    await this.setupFirebase(merchantDetails);

    this.localStorageService.setItem<MerchantUser>(
      LocalStorageKey.User,
      merchantDetails,
    );

    return merchantDetails;
  }

  public getErrorMessageKey(err) {
    if (err.message?.startsWith('UserMigration failed with error ')) {
      // eslint-disable-next-line no-param-reassign
      err.message = err.message.replace('UserMigration failed with error ', '');
    }

    if (err.message.includes('User is disabled.')) {
      err.message =
        'Your account has been disabled, please contact the customer support team for futher details.';
    }
    if (
      err.code === 'UserLambdaValidationException' &&
      err.message ===
        'PreAuthentication failed with error User is not confirmed.'
    ) {
      err.message = 'User is not confirmed';
    }
    return getCognitoErrorMessageKey(err.message);
  }

  private async loginOnCognito(loginDetails: LoginDetails) {
    const response: CognitoIdToken = await this.authService.login(loginDetails);
    this.authService.setIdToken(response);
    this.localStorageService.removeItem(LocalStorageKey.returnUrl);
    return response;
  }

  private getMerchantDetails() {
    return lastValueFrom(
      this.merchantService.getMerchantByEmail(
        this.authService.getAuthenticatedUser().getUsername(),
      ),
    );
  }

  public getDeliveryMediumForUser(
    email: string,
  ): Promise<{ medium: DeliveryMediumType; text: string }> {
    return lastValueFrom(this.merchantService.getDeliveryMediumForUser(email));
  }

  private async setupFirebase(merchant) {
    await this.firebaseAuthenticationService.authenticateUser(merchant);
    await this.firebaseUserService.initializeUser(merchant);
    await this.firebaseNotificationService.initializeNotification(merchant);
  }

  async getFrillTokenAndRedirect(redirectUrl, userId): Promise<void> {
    const token = await lastValueFrom(
      this.merchantService.getFrillToken(userId),
    );
    const url = `${redirectUrl}?ssoToken=${token}`;
    window.location.href = url;
  }

  public logout(): void {
    this.cleanupFirebase();
    this.authService.logout();
    this.userService.clearUser();
  }

  private cleanupFirebase() {
    this.firebaseNotificationService.unregisterListener();
    this.firebaseUserService.unregisterListener();
    this.firebaseAuthenticationService.signOutUser();
  }

  public async refreshUser(): Promise<void> {
    const merchantDetails = await this.getMerchantDetails();
    const isReseller = await firstValueFrom(
      this.referralProgramService.findUserIsReseller(),
    );
    // eslint-disable-next-line @typescript-eslint/dot-notation
    merchantDetails['isReseller'] = isReseller;
    this.userService.hasTheUserBeenRefreshed(true);
    this.userService.setUser(merchantDetails as AuthUser);
    this.localStorageService.setItem<MerchantUser>(
      LocalStorageKey.User,
      merchantDetails,
    );
    if (!this.firebaseUserService.getFirebaseUser()) {
      await this.setupFirebase(merchantDetails);
    }
  }

  public async getAuthorizationToken(): Promise<string> {
    return this.authService.getCognitoIdentityToken();
  }
}
