import { Injectable } from '@angular/core';
import * as FullStory from '@fullstory/browser';
import { datadogRum } from '@datadog/browser-rum';
import {
  KeyboardShortcutTriggeredEvent,
  OfflineTrackingInvoice,
  PosOfflineSaleInvoiceSyncEvent,
  PosSaleInvoiceCreatedEvent,
  PosSaleInvoiceSyncEvent,
} from '../types/event-tracking.type';
import {
  PosSaleInvoiceMode,
  TrackingEventName,
} from '../constants/event-tracking.const';

@Injectable()
export class EventTrackingService {
  /**
   * @deprecated
   */
  private trackOfflineInvoiceEvent(
    trackingInvoice: OfflineTrackingInvoice,
    eventType: TrackingEventName,
  ) {
    FullStory.event(eventType, {
      creationDate_date: trackingInvoice.completeDate,
      invoiceCreationTimeTakenInMS_int: trackingInvoice.timeTakenInMilliSeconds,
      invoiceNumber_str: trackingInvoice.displayInvoiceNumber,
      userId_int: trackingInvoice.userId,
      isOfflineFirst_bool: trackingInvoice.offlineFirst,
      isPlugin_bool: trackingInvoice.isPlugin,
    });
    this.trackOnDataDog(eventType, { ...trackingInvoice });
  }

  /**
   * @deprecated
   */
  public trackOfflineInvoiceCreated(
    trackingInvoice: OfflineTrackingInvoice,
  ): void {
    this.trackOfflineInvoiceEvent(
      trackingInvoice,
      TrackingEventName.OfflineInvoiceCreated,
    );
  }

  /**
   * @deprecated
   */
  public trackOfflineInvoiceSynced(
    trackingInvoices: OfflineTrackingInvoice[],
  ): void {
    trackingInvoices.forEach((trackingInvoice) => {
      this.trackOfflineInvoiceEvent(
        trackingInvoice,
        TrackingEventName.OfflineInvoiceSynced,
      );
    });
  }

  keyboardShortcutTriggered(data: KeyboardShortcutTriggeredEvent): void {
    this.trackOnDataDog(TrackingEventName.KeyboardShortcutTriggered, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.KeyboardShortcutTriggered, {
      shortcut_str: data.shortcut,
      context_str: data.context,
      action_str: data.action,
    });
  }

  posSaleInvoiceCreated(data: PosSaleInvoiceCreatedEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceCreated, { ...data });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceCreated, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
      pluginInstalled_bool: data.pluginInstalled,
      pluginPrint_bool: data.pluginPrint,
      browserPrint_bool: data.browserPrint,
      fallbackBrowserPrint_bool: data.fallbackBrowserPrint,
      executionTime_real: data.executionTime,
      printTime_real: data.printTime,
    });
  }

  posSaleInvoiceCreationFailed(mode: PosSaleInvoiceMode): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceCreationFailed, {
      mode,
    });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceCreationFailed, {
      mode_str: mode,
    });
  }

  posSaleInvoiceSynced(data: PosSaleInvoiceSyncEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceSynced, { ...data });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceSynced, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
    });
  }

  posSaleInvoiceSyncingFailed(data: PosSaleInvoiceSyncEvent): void {
    this.trackOnDataDog(TrackingEventName.PosSaleInvoiceSyncingFailed, {
      ...data,
    });
    this.trackOnFullStory(TrackingEventName.PosSaleInvoiceSyncingFailed, {
      invoiceNumber_str: data.invoiceNumber,
      mode_str: data.mode,
    });
  }

  posOfflineSaleInvoiceSyncCompleted(
    data: PosOfflineSaleInvoiceSyncEvent,
  ): void {
    this.trackOnDataDog(TrackingEventName.PosOfflineSaleInvoiceSyncCompleted, {
      ...data,
    });
    this.trackOnFullStory(
      TrackingEventName.PosOfflineSaleInvoiceSyncCompleted,
      {
        shiftId_int: data.shiftId,
        syncedCount_int: data.syncedCount,
        totalCount_int: data.totalCount,
      },
    );
  }

  private trackOnFullStory(
    eventName: TrackingEventName,
    properties: Record<string, unknown>,
  ): void {
    FullStory.event(eventName, properties);
  }

  private trackOnDataDog(
    eventName: TrackingEventName,
    properties: Record<string, unknown>,
  ): void {
    datadogRum.addAction(eventName, properties);
  }
}
