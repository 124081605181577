<div *ngIf="control" class="flex flex-column" [ngClass]="{rtl: isRTL}">
    <span class="p-float-label">
        <input [attr.pendo-tracker]="context + '-' + label.toLowerCase().split(' ').join('_')+'_input'"  [type]="type ? type : 'text'" pInputText class="input-wrap" [formControl]="control"  />
        <label class="rw-fs-3"><span *ngIf="required" class="required-astrik">*</span> {{ label | translate }}</label>
        <button [attr.pendo-tracker]="context + '-' + label.toLowerCase().split(' ').join('_')+'_clear'"
        *ngIf="!control.disabled && !!control.value"
          pButton
          pRipple
          type="button"
          icon="pi pi-times"
          class="reset-button p-button-rounded p-button-danger"
          [ngClass]="{'active':displayResetButton}"
          (click)="reset()"
        ></button>
      </span>
      <small>{{ helpText }}</small>
      
</div>