export class CreateMerchantAppSubscriptionToStockLocation {
  id?: number;

  merchantAppSubscriptionId: number;

  stockLocationId: number;

  tenantId: number;

  constructor(
    merchantAppSubscriptionToStockLocation: CreateMerchantAppSubscriptionToStockLocation,
  ) {
    this.id = merchantAppSubscriptionToStockLocation.id;
    this.merchantAppSubscriptionId =
      merchantAppSubscriptionToStockLocation.merchantAppSubscriptionId;
    this.stockLocationId =
      merchantAppSubscriptionToStockLocation.stockLocationId;
    this.tenantId = merchantAppSubscriptionToStockLocation.tenantId;
  }
}

export class CreateAppSubscriptionAttribute {
  name: string;

  value: string;

  id: number;

  merchantAppSubscriptionId: number;

  tenantId: number;

  constructor(appSubscriptionAttribute: CreateAppSubscriptionAttribute) {
    this.name = appSubscriptionAttribute.name;
    this.value = appSubscriptionAttribute.value;
    this.id = appSubscriptionAttribute.id;
    this.merchantAppSubscriptionId =
      appSubscriptionAttribute.merchantAppSubscriptionId;
    this.tenantId = appSubscriptionAttribute.tenantId;
  }
}

export class CreateMerchantAppSubscription {
  tenantId: number;

  webHookKey: string;

  appName: string;

  stockLocationId: number;

  expiryDate: Date;

  id: number;

  appSubscriptionAttributes: CreateAppSubscriptionAttribute[];

  merchantAppSubscriptionToStockLocation: CreateMerchantAppSubscriptionToStockLocation;

  schemaName: string;

  constructor(merchantAppSubscription: CreateMerchantAppSubscription) {
    this.tenantId = merchantAppSubscription.tenantId;
    this.webHookKey = merchantAppSubscription.webHookKey;
    this.appName = merchantAppSubscription.appName;
    this.stockLocationId = merchantAppSubscription.stockLocationId;
    this.expiryDate = merchantAppSubscription.expiryDate;
    this.id = merchantAppSubscription.id;
    this.appSubscriptionAttributes =
      merchantAppSubscription.appSubscriptionAttributes;
    this.merchantAppSubscriptionToStockLocation =
      merchantAppSubscription.merchantAppSubscriptionToStockLocation;
    this.schemaName = merchantAppSubscription.schemaName;
  }
}

export type UninstallAppSubscription = {
  tenantId: number;
  appName: string;
};
