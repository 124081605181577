import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Observable, Subscription, from, map, timer } from 'rxjs';
import { Merchant } from 'src/app/shared/model/Merchant';
import { getLocalizedUrl } from 'src/app/utils/localizedUrl';
import { CustomToastService } from '../../shared/services/custom-toast.service';

import { CognitoUtility } from '../../core/utils/CognitoUtility';
import { AuthUser, LoginDetails, SignupConfirmation } from '../auth.models';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { LocalStorageKey } from '../../shared/constants';
import { CubeJsConfig } from '../../reports/services/cubejs.config';

@Injectable()
export class AuthService {
  registeredUser: CognitoUser;

  changeLanguageSubscription: Subscription;

  newJwtTokenPromise: Promise<string>;

  constructor(
    private translate: TranslateService,
    private customToast: CustomToastService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public register(user: AuthUser): Observable<boolean> {
    const userPool = CognitoUtility.getUserPoolConfigurations();

    return new Observable((obs) => {
      userPool.signUp(
        user.email,
        user.password,
        CognitoUtility.buildSignupAttributes(user),
        null,
        (err, result) => {
          if (err) {
            obs.error(err);
            return;
          }
          this.registeredUser = result.user;
          obs.next(true);
        },
      );
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public forgotPassword(email: string): Observable<boolean> {
    const userPool = CognitoUtility.getUserPoolConfigurations();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Observable((subscriber) => {
      cognitoUser.forgotPassword({
        onFailure(err) {
          subscriber.error(err);
        },
        onSuccess() {
          subscriber.next(true);
        },
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public resetPassword(
    email: string,
    password: string,
    vCode: string,
  ): Observable<boolean> {
    const userPool = CognitoUtility.getUserPoolConfigurations();
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Observable((subscriber) => {
      cognitoUser.confirmPassword(vCode, password, {
        onFailure(err) {
          subscriber.error(err);
        },
        onSuccess() {
          subscriber.next(true);
        },
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  confirm(confirmation: SignupConfirmation): Observable<boolean> {
    const userPool = CognitoUtility.getUserPoolConfigurations();

    const userData = {
      Username: confirmation.email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return new Observable((subscriber) => {
      cognitoUser.confirmRegistration(confirmation.code, false, (err) => {
        if (err) {
          subscriber.error(err);
          return;
        }
        subscriber.next(true);
      });
    });
  }

  switchLanguage(lang: string) {
    if (this.changeLanguageSubscription) { this.changeLanguageSubscription.unsubscribe(); }
    this.translate.get(['langChangeMessage']).subscribe((translations) => {
      this.customToast.success(translations.langChangeMessage);
    });
    this.changeLanguageSubscription = timer(2000).subscribe(() => {
      this.localStorageService.setItem<string>(LocalStorageKey.Language, lang);
      window.location.pathname = getLocalizedUrl(lang);
    });
  }

  async login(login: LoginDetails): Promise<CognitoIdToken> {
    const userLoginDetails = {
      Username: login.email,
      Password: login.password,
    };

    const authenticationDetails = new AuthenticationDetails(userLoginDetails);

    const userPool = CognitoUtility.getUserPoolConfigurations();

    const userData = {
      Username: login.email.toLowerCase(),
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    const cognitoUserSession = await new Promise<CognitoUserSession>(
      (resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      },
    );
    const idToken = cognitoUserSession.getIdToken();

    this.setIdToken(idToken);

    return idToken;
  }

  isAuthenticated(): Observable<boolean> {
    return from(this.getCognitoIdentityToken()).pipe(
      map((token) => !!token?.length),
    );
  }

  setIdToken(token: CognitoIdToken): void {
    this.localStorageService.setItem<string>(
      LocalStorageKey.IdToken,
      token.getJwtToken(),
    );
    const expireTime = token.getExpiration(); // seconds
    this.setExpiryTime(expireTime);
    CubeJsConfig.setIdToken(token.getJwtToken());
  }

  needsRefresh(): boolean {
    const time = this.localStorageService.getItem<number>(
      LocalStorageKey.needsRefresh,
    );
    return time < Date.now() / 1000;
  }

  setExpiryTime(expireTime: number): void {
    this.localStorageService.setItem<number>(
      LocalStorageKey.needsRefresh,
      expireTime,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getAuthenticatedUser() {
    return CognitoUtility.getUserPoolConfigurations().getCurrentUser();
  }

  removeLocalStorageKeysExceptSavedColumns() {
    this.localStorageService.allKeys().forEach((key) => {
      if (key.indexOf('columns') === -1) {
        this.localStorageService.removeItem(key);
      }
    });
  }

  logout() {
    if (this.getAuthenticatedUser()) {
      this.getAuthenticatedUser().signOut();
    }

    const lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    const latitude = this.localStorageService.getItem<string>(
      LocalStorageKey.Latitude,
    );
    const longitude = this.localStorageService.getItem<string>(
      LocalStorageKey.Longitude,
    );
    const referralLink = this.localStorageService.getItem<string>(
      LocalStorageKey.referralLink,
    );
    this.removeLocalStorageKeysExceptSavedColumns();
    this.localStorageService.setItem<string>(LocalStorageKey.Language, lang);
    if (latitude && longitude) {
      this.localStorageService.setItem<string>(
        LocalStorageKey.Latitude,
        latitude,
      );
      this.localStorageService.setItem<string>(
        LocalStorageKey.Longitude,
        longitude,
      );
    }
    if (referralLink) {
      this.localStorageService.setItem<string>(
        LocalStorageKey.referralLink,
        referralLink,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  resendConfirmationCode(email): Observable<boolean> {
    const userPool = CognitoUtility.getUserPoolConfigurations();

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    return new Observable((observer) => {
      cognitoUser.resendConfirmationCode((err) => {
        if (err) {
          observer.error(err);
          return;
        }
        observer.next(true);
      });
    });
  }

  onActivateUser = (activateData: any): Observable<Merchant> => this.http.post<Merchant>('/api/merchants/activate', activateData);

  // eslint-disable-next-line class-methods-use-this
  getCurrentUserId() {
    const { id } = this.localStorageService.getItem<AuthUser>(
      LocalStorageKey.User,
    );
    return id;
  }

  getSchemaName = (email: string): Observable<string> => this.http.get<string>(`/api/merchants/schemaName/${email}`);

  registerMerchant = (user: AuthUser): Observable<any> => this.http.post<any>('/api/merchants/register', user);

  logAccountDisabled(msg: any) {
    return this.http.post<any>('/api/merchants/log_account_disabled', msg);
  }

  validateLicenseKey(licenseKey: string) {
    return this.http.get<any>(`/api/merchants/validate/license-key/${licenseKey}`);
  }

  public async getCognitoIdentityToken(): Promise<string> {
    const cognitoUser = this.getAuthenticatedUser();

    if (!cognitoUser) {
      return '';
    }

    const userSession = await new Promise<CognitoUserSession>(
      (resolve, reject) => {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
          }
          resolve(session as CognitoUserSession);
        });
      },
    );

    const idToken = userSession.getIdToken();
    CubeJsConfig.setIdToken(idToken.getJwtToken());

    // Linux epoch timestamp in seconds
    const expiration = idToken.getExpiration();
    const fiveMinutesInSeconds = 300;
    const currentTimeEpochMilliseconds = Date.now();
    const currentTimeEpochSeconds = currentTimeEpochMilliseconds / 1000;

    // Refresh token if its expired or about to expire in 5 minutes
    if (expiration > currentTimeEpochSeconds + fiveMinutesInSeconds) {
      return idToken.getJwtToken();
    }

    if (!this.newJwtTokenPromise) {
      const refreshToken = userSession.getRefreshToken();

      this.newJwtTokenPromise = new Promise<CognitoUserSession>(
        (resolve, reject) => {
          cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(err);
            }
            resolve(session as CognitoUserSession);
          });
        },
      )
        .then((newSession) => {
          const jwtToken = newSession.getIdToken().getJwtToken();
          this.setIdToken(newSession.getIdToken());
          return jwtToken;
        })
        .catch((err) => {
          console.error(err);
          return '';
        })
        .finally(() => {
          this.newJwtTokenPromise = undefined;
        });
    }

    return this.newJwtTokenPromise;
  }

  /**
   * Setting to local storage for some backward compatibility only since
   * we are using this in some places like web sockets but for all api
   * calls the @see getAuthorizationToken from @see UserAuthenticationService
   * is used which can refresh the token under the hood if needed
   */
  private setJwtTokenToLocalStorage(jwtToken: string) {
    this.localStorageService.setItem<string>(LocalStorageKey.IdToken, jwtToken);
  }
}
