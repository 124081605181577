<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>
<div *ngIf = "!smsOtpFeatureFlag" class="auth-page-1 min-h-screen flex align-items-center align-items-center">
  <div class="auth-page-inner-1 mx-auto">
    <rw-language-switcher [changeLang]="changeLang"></rw-language-switcher>
    <p-card class="block mt-4" styleClass="p-2 md:p-5">
      <form
        [formGroup]="fGroup"
        #ngForm="ngForm"
        *ngIf="!isVerificationCodeSent"
        class="p-fluid grid"
      >
        <div class="field col-12">
          <h3 [ngClass]="lang === 'ar' ? 'text-right' : ''">
            {{ 'Forgot Your Password?' | translate }}
          </h3>
          <p class="text-grey-50">
            {{'Please enter your email address to send a verification code' | translate}}
          </p>
        </div>
        <div class="field col-12">
          <span class="p-float-label" [ngClass]="lang === 'en' ? 'p-input-icon-right' : 'p-input-icon-left'">
            <i
              *ngIf="fGroup.get('email').value"
              aria-label="Clear"
              (click)="fGroup.get('email').patchValue()"
              class="fa-solid fa-circle-xmark fa-lg form-clear-icon cursor-pointer"></i>
            <input
              formControlName="email"
              pInputText
              type="email"
              id="email"
            />
            <label>{{ 'Email' | translate }}</label>
          </span>
          <small
            *ngIf="email.touched && email.invalid"
            class="flex align-items-center p-error"
          >
            <i class="fa-regular fa-circle-exclamation mr-1"></i>
            <span> {{ 'Email is required' | translate }} </span>
          </small>
        </div>
        <div class="field col-12 mb-0 pt-5 flex align-items-center justify-content-between">
          <button
            pButton pRipple
            class="w-auto p-button-text p-button-lg rw__fs-8"
            [routerLink]="['/login']"
          >
            {{ 'Cancel' | translate }}
          </button>
          <button pButton pRipple
            [disabled]="fGroup.invalid || isShowSpinner"
            type="button"
            (click)="startResetPasswordV1(fGroup.value)"
            class="w-auto p-button-lg auth-btn rw__fs-8"
            label="{{ 'Send' | translate }}"
          >
          </button>
        </div>
      </form>
      <div *ngIf="isVerificationCodeSent">
        <rw-reset-new-password
          [userEmail]="userEmail"
        ></rw-reset-new-password>
      </div>
    </p-card>
  </div>
</div>
<!-- <rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner> -->
<div *ngIf="smsOtpFeatureFlag" id="rw__auth-page" class="auth-page bg-teal-90 min-h-screen md:flex w-full align-items-center justify-content-center flex-row-reverse" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  <rw-side-panel></rw-side-panel>

  <div class="auth-form-area bg-white flex align-items-center justify-content-center w-full mx-0 md:mx-3">
    <div class="py-5 px-3">
      <div class="flex align-items-center justify-content-between gap-2 md:gap-5 pb-5">
        <img class="max-w-full h-auto d-block auth-logo" src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="">
        <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      <form
      [formGroup]="fGroup"
      #ngForm="ngForm"
      *ngIf="!isVerificationCodeSent"
      class="p-fluid grid auth-form"
    >
      <div class="field col-12 rw__pb-0">
        <label class="rw__fs-6 fw-500 rw__mb-3">{{'Forgot Your Password?' | translate}}</label>
        <p class="rw__fs-9 text-grey-60 rw__mb-3">{{ 'Please enter your email address to send a verification code' | translate }}</p>
      </div>
      <!-- <div class="field col-12 rw__mb-5">
        <rw-input-error *ngIf="(code.touched && code.invalid) || fGroup.get('code').errors?.cannotContainSpace"
          [text]="'Code is required' | translate" class="block rw__mt-2"></rw-input-error>
          <rw-input-error *ngIf="errMessage && code.valid"
          [text]="'errMessage' | translate" class="block rw__mt-2"></rw-input-error>
      </div> --> 

      <!-- EMAIL INPUT -->
      <div class="field col-12" [ngClass]="changeLang !== 'ar' ? 'rw__input-text-end' : ''">
        <rw-input  pendo-tracker="rpi" [required]="true" name="email" [label]="'Email' | translate" [control]="fGroup.get('email')"></rw-input>
        <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
          <div class="flex align-items-center mt-1">
            <i class="fa-regular fa-circle-exclamation p-error"></i>
            <small *ngIf="email.errors.required" class="p-error block mx-1">{{ 'Email is required' | translate }}</small>
            <small *ngIf="email.errors.pattern" class="p-error block mx-1">{{ 'Not valid email' | translate }}</small>
          </div>
        </ng-container>
      </div>
      <div class="field col-12 rw__mb-0 text-center">
        <button  pendo-tracker="rpb-1" pButton pRipple type="button" 
        class="rw__button w-full" 
        [ngClass]="userEmail === '' ? 'disabled' : ''"
        label="{{'Send' | translate}}"
        (click)="startResetPassword(fGroup.value)" [disabled]="
          fGroup.invalid ||
        isShowSpinner"></button>
        <div class="rw__my-2 text-center w-full">
          <button pendo-tracker="rpb"
            pButton pRipple
            class="w-full p-button-text p-button-lg rw__fs-8"
            [routerLink]="['/login']"
            label="{{'Cancel' | translate}}"
            class="rw__button p-button-text w-auto rw__px-0 bg-transparent"
          >
          </button>
        </div>       
        <hr class="rw__mb-4" />
        <span class="rw__my-3">
          {{ 'Need Help?' | translate }} 
          <button  class="rw__button text-primary border-0 bg-transparent p-button-text" (click)="startIntercom()">{{
            'Contact Support' | translate
          }}</button>
        </span>
      </div>
    </form>


      <div *ngIf="isVerificationCodeSent">
        <rw-reset-new-password
          [userEmail]="userEmail"
          [userPhone]="userPhone"
          [message]="message"
          [resendConfirmationCode]="resendConfirmationCode"
          [sendToEmailButtonVisibility]= "sendToEmailButtonVisibility"
        ></rw-reset-new-password>
      </div>
    </div>
  </div>
</div>