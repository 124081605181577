<div class="selector" [ngClass]="changeLang == 'ar'?'rtl':'ltr'">
    <div class="selector-item">
        <input pendo-tracker="lsi" type="radio" [checked]="changeLang ==='en' ? true :false " (click)="switchLang('ar')" id="radio1"
            name="selector" class="selector-item_radio" checked>
        <label for="radio1" class="selector-item_label">عربي</label>
    </div>
    <div class="selector-item">
        <input pendo-tracker="lsi-1" type="radio" id="radio2" [checked]="changeLang ==='ar' ? true : false" (click)="switchLang('en')"
            name="selector" class="selector-item_radio">
        <label for="radio2" class="selector-item_label cursor-pointer">English</label>
    </div>
</div>