import { ProductEnums, ProductTypes, TaxType } from '@rewaa-team/types';
import {
  CartTypes,
  InvoiceEnums,
  promotionEngineService,
  promotionService,
  PromotionTypes,
} from '..';
import { v4 } from 'uuid';
import { DiscountTypeConstant, TaxMethodConstant } from './enums';
import {
  AmountSummary,
  Cart,
  Discount,
  DiscountSummary,
  ExtraLineItem,
  LineItem,
  LineItemFindByParam,
  LineItemPrice,
  LineItemTax,
  LineItemTaxLine,
  PromotionSummary,
  Tax,
  TaxBreakdown,
  TaxLine,
  TaxLineBreakdown,
  TaxSummary,
  TrackDetail,
  ValidationSettings,
} from './types';
import {
  InvoicePromotions,
  PromotionDetails,
  PromotionDiscountType,
} from '../promotions/types';
import { CommonCalculationService } from '../common/common-calculations.service';
import { cartValidationService } from './cart-validation.service';
import {
  InvalidSearchParameters,
  InvalidSerialNumberError,
  ProductNotFoundInCart,
} from './errors';
import { AmountType, AmountTypeConstant } from '../common/enums';

export abstract class CartCalculationService extends CommonCalculationService {
  public getNewCart(
    priceMode: InvoiceEnums.PriceMode,
    taxMode: TaxType,
    categories: ProductTypes.Category[],
    validationSettings: ValidationSettings,
    applyPromotions: boolean,
  ): CartTypes.Cart {
    return {
      id: v4(),
      lineItems: [],
      applyPromotions,
      priceMode,
      taxMode,
      note: '',
      categories,
      promotionGroups: [],
      discount: {
        unitAmount: 0,
        amountType: AmountTypeConstant.Percentage,
        rate: 0,
        type: DiscountTypeConstant.Invoice,
        total: 0,
        name: '',
        totalWithTax: 0,
      },
      taxSummary: [],
      discountSummary: {
        [DiscountTypeConstant.Promotion]: 0,
        [DiscountTypeConstant.Invoice]: 0,
        [DiscountTypeConstant.Product]: 0,
      },
      amountSummary: {
        total: 0,
        subtotal: 0,
        totalTax: 0,
        totalDiscounts: 0,
        totalWithoutTax: 0,
        subtotalWithTax: 0,
      },
      promotionSummary: [],
      validationSettings,
      validationErrors: {
        lineItemErrors: [],
      },
    };
  }

  public resetCart(
    oldCart: Cart,
    partialCart: Partial<Cart> = {},
    recalculate = false,
  ): CartTypes.Cart {
    const newCart = this.getNewCart(
      oldCart.priceMode,
      oldCart.taxMode,
      oldCart.categories,
      oldCart.validationSettings,
      oldCart.applyPromotions,
    );
    const cart: Cart = Object.assign(
      newCart,
      JSON.parse(JSON.stringify(partialCart)),
    );
    if (recalculate) {
      return this.calculateCart(cart);
    }
    return cart;
  }

  public resetCartId(cart: Cart): CartTypes.Cart {
    return this.resetCart(cart, { ...cart, id: v4() });
  }

  /**
   *
   * @param trackDetails
   * @param packRate the quantity of item in a pack, 1 if the item is not a package
   * @returns the quantity of the item based on its track quantity and pack rate
   */
  protected getTrackQuantity(
    trackDetails: TrackDetail[],
    packRate: number,
  ): number {
    const quantity = trackDetails.reduce(
      (acc, track) => this.add(acc, track.quantity),
      0,
    );
    return this.divide(quantity, packRate);
  }

  /**
   *
   * @param cart The current cart
   * @param item The line item to add to cart
   * @param quantity The quantity of the item to add
   * @param trackNumbers An array of string track numbers for adding serial products
   * @returns
   */
  addLineItem(cart: Cart, item: LineItem, quantity: number): Cart {
    const { lineItems } = cart;

    return this.calculateCart({
      ...cart,
      lineItems: [{ ...item, quantity }, ...lineItems].map(
        (lineItem, index) => ({ ...lineItem, index }),
      ),
    });
  }

  public removeLineItem(
    cart: Cart,
    findBy: CartTypes.LineItemFindByParam,
  ): Cart {
    const { lineItems } = cart;
    const lineItemIndex = this.getLineItemIndex(lineItems, findBy);

    return this.calculateCart({
      ...cart,
      lineItems: [
        ...lineItems.slice(0, lineItemIndex),
        ...lineItems.slice(lineItemIndex + 1),
      ].map((lineItem, index) => ({ ...lineItem, index })),
    });
  }

  /**
   *
   * @param tracks
   * @param trackNumbers
   * @returns
   */
  protected getSerialTrackDetails(
    tracks: TrackDetail[],
    trackNumbers: string[],
  ): TrackDetail[] {
    const existingTrackNumbers = tracks
      .filter((track) => track.quantity > 0)
      .map((track) => track.trackNo);
    const allTrackNumbers = [...trackNumbers, ...existingTrackNumbers];
    const newTracks = tracks.map((track): TrackDetail => {
      const serial = allTrackNumbers.find(
        (trackNo) => track.trackNo === trackNo,
      );
      if (!serial) {
        return {
          ...track,
          quantity: 0,
        };
      }
      return {
        ...track,
        quantity: 1,
      };
    });

    allTrackNumbers.forEach((trackNo) => {
      const serial = tracks.find((track) => track.trackNo === trackNo);
      if (!serial) {
        throw new InvalidSerialNumberError(trackNo);
      }
    });

    return newTracks;
  }

  /**
   * adds the serial numbers to cart
   * removes any existing serials if the product is already in cart
   * @param cart
   * @param serialNumbers
   * @param findBy
   * @returns
   */
  public updateSerialNumbersForLineItems(
    cart: Cart,
    serialNumbers: string[],
    findBy: CartTypes.LineItemFindByParam,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    const trackDetails = this.getSerialTrackDetails(
      cart.lineItems[lineItemIndex].trackDetails,
      serialNumbers,
    );
    let quantity = trackDetails.reduce((acc, track) => acc + track.quantity, 0);
    if (
      cart.lineItems[lineItemIndex].type === ProductEnums.VariantType.Package
    ) {
      quantity /= cart.lineItems[lineItemIndex].packs[0].rate;
    }
    return this.updateLineItem(cart, lineItemIndex, { trackDetails, quantity });
  }

  /**
   * adds the track quantities for a lineItem in the cart
   * @param cart
   * @param trackDetails array of track details, will set track quantity to 0 for tracks details that are not given.
   * @param findBy
   * @returns
   */
  public updateTrackQuantitiesForLineItem(
    cart: Cart,
    trackDetails: TrackDetail[],
    findBy: CartTypes.LineItemFindByParam,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    const lineItem = cart.lineItems[lineItemIndex];
    const updatedTrackDetails = lineItem.trackDetails.map((track) => {
      const updatedTrack = trackDetails.find(
        (newTrack) => newTrack.trackNo === track.trackNo,
      );
      const quantity = updatedTrack?.quantity || 0;
      return {
        ...track,
        quantity,
      };
    });
    let quantity = trackDetails.reduce((acc, track) => acc + track.quantity, 0);
    if (
      cart.lineItems[lineItemIndex].type === ProductEnums.VariantType.Package
    ) {
      quantity /= cart.lineItems[lineItemIndex].packs[0]?.rate || 1;
    }
    return this.updateLineItem(cart, lineItemIndex, {
      trackDetails: updatedTrackDetails,
      quantity,
    });
  }

  public updateLineItemDiscount(
    cart: Cart,
    discount: number,
    amountType: AmountType,
    findBy: CartTypes.LineItemFindByParam,
    taxMode?: TaxType,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    const lineItem = cart.lineItems[lineItemIndex];
    const lineItemDiscounts = lineItem.discounts;
    if (!taxMode) {
      taxMode = cart.taxMode;
    }
    const unitAmount =
      taxMode === TaxType.Exclusive
        ? discount
        : this.calculateTaxExclusiveFromTaxInclusive(
            discount,
            lineItem.tax.rate,
          );
    const newDiscount: Discount = {
      name: '',
      rate: discount,
      amountType,
      type: DiscountTypeConstant.Product,
      unitAmount,
      total: unitAmount,
      totalWithTax: unitAmount,
    };
    const discounts = lineItemDiscounts.filter(
      (disc) =>
        disc.type !== DiscountTypeConstant.Product &&
        disc.type !== DiscountTypeConstant.Invoice,
    );
    cart.discount = {
      unitAmount: 0,
      amountType: AmountTypeConstant.Percentage,
      rate: 0,
      type: DiscountTypeConstant.Invoice,
      total: 0,
      name: '',
      totalWithTax: 0,
    };
    discounts.push(newDiscount);
    return this.updateLineItem(cart, lineItemIndex, { discounts });
  }

  public updateLineItemPrice(
    cart: Cart,
    price: number,
    findBy: CartTypes.LineItemFindByParam,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    const lineItem: LineItem = cart.lineItems[lineItemIndex];
    const newPriceExclusive =
      cart.taxMode === TaxType.Exclusive
        ? price
        : this.calculateTaxExclusiveFromTaxInclusive(price, lineItem.tax.rate);
    const originalPrice =
      cart.priceMode === InvoiceEnums.PriceModeConstant.Retail
        ? lineItem.priceTaxExclusive.retail
        : lineItem.priceTaxExclusive.wholesale;
    const base =
      newPriceExclusive > originalPrice ? newPriceExclusive : originalPrice;

    const priceTaxExclusive: LineItemPrice = {
      ...lineItem.priceTaxExclusive,
      base,
    };
    const discounts = lineItem.discounts.filter(
      (disc) => disc.type !== DiscountTypeConstant.Product,
    );
    const cartWithUpdatedPrice = this.updateLineItem(cart, lineItemIndex, {
      priceTaxExclusive,
      discounts,
    });

    if (newPriceExclusive > originalPrice) {
      return cartWithUpdatedPrice;
    }

    const discount = base
      ? this.subtract(1, this.divide(newPriceExclusive, base))
      : 0;
    const amountType = AmountTypeConstant.Percentage;
    return this.updateLineItemDiscount(
      cartWithUpdatedPrice,
      discount,
      amountType,
      findBy,
    );
  }

  /**
   *
   * @param cart
   * @param discount The discount amount itself for 1 quantity if type is 'Fixed'. The discount rate as a decimal (e.g., 0.15 for 15% discount) if type is 'Percentage'.
   * @param amountType 'Fixed' or 'Percentage'
   */
  public updateInvoiceDiscount(
    cart: Cart,
    discount: number,
    amountType: AmountType,
    taxMode?: TaxType,
  ): Cart {
    let rate = discount;
    let unitAmount = discount;
    const { subtotal, subtotalWithTax } = cart.amountSummary;
    if (!taxMode) {
      taxMode = cart.taxMode;
    }
    if (amountType === AmountTypeConstant.Fixed) {
      rate =
        taxMode === TaxType.Exclusive
          ? this.divide(discount, subtotal)
          : this.divide(discount, subtotalWithTax);
    }
    if (taxMode === TaxType.Inclusive) {
      unitAmount = this.multiply(rate, subtotal);
    }
    const invoiceDiscount: Discount = {
      rate,
      unitAmount,
      total: unitAmount,
      name: '',
      amountType,
      type: DiscountTypeConstant.Invoice,
      totalWithTax: unitAmount,
    };

    const lineItems = cart.lineItems.map((lineitem) => ({
      ...lineitem,
      discounts: lineitem.discounts.filter(
        (discount) => discount.type !== DiscountTypeConstant.Product,
      ),
    }));
    return this.calculateCart({
      ...cart,
      discount: invoiceDiscount,
      lineItems,
    });
  }

  public abstract updateLineItemQuantity(
    cart: Cart,
    findBy: LineItemFindByParam,
    quantity: number,
  ): Cart;

  public updateLineItemExtraQuantity(
    cart: Cart,
    findBy: LineItemFindByParam,
    extraFindBy: LineItemFindByParam,
    quantity: number,
  ): Cart {
    const lineItemIndex = this.getLineItemIndex(cart.lineItems, findBy);
    if (lineItemIndex < 0 || !cart.lineItems[lineItemIndex].extras.length) {
      throw new ProductNotFoundInCart(findBy);
    }
    const extraLineItemIndex = this.getLineItemIndex(
      cart.lineItems[lineItemIndex].extras,
      extraFindBy,
    );
    const extras = JSON.parse(
      JSON.stringify(cart.lineItems[lineItemIndex].extras),
    );
    extras[extraLineItemIndex].quantity = quantity;
    return this.updateLineItem(cart, lineItemIndex, { extras });
  }

  public updatePriceMode(cart: Cart, priceMode: InvoiceEnums.PriceMode): Cart {
    const lineItems: LineItem[] = JSON.parse(
      JSON.stringify(cart.lineItems),
    ).map(
      (lineItem: LineItem): LineItem => ({
        ...lineItem,
        priceTaxExclusive: {
          base:
            priceMode === InvoiceEnums.PriceModeConstant.Wholesale
              ? lineItem.priceTaxExclusive.wholesale
              : lineItem.priceTaxExclusive.retail,
          retail: lineItem.priceTaxExclusive.retail,
          wholesale: lineItem.priceTaxExclusive.wholesale,
          final: 0,
          extrasTotal: 0,
        },
      }),
    );
    return this.calculateCart({
      ...cart,
      priceMode,
      lineItems,
    });
  }

  public updateNote(cart: Cart, note: string): Cart {
    return this.resetCart(cart, {
      ...cart,
      note,
    });
  }

  protected updateLineItem(
    cart: Cart,
    lineItemIndex: number,
    partialLineItem: Partial<LineItem>,
  ): Cart {
    const lineItems = JSON.parse(JSON.stringify(cart.lineItems));
    Object.assign(lineItems[lineItemIndex], partialLineItem);
    return this.calculateCart({ ...cart, lineItems });
  }

  public getLineItem(cart: Cart, findBy: LineItemFindByParam): LineItem {
    const index = this.getLineItemIndex(cart.lineItems, findBy);
    return JSON.parse(JSON.stringify(cart.lineItems[index]));
  }

  public getLineItems(cart: Cart): LineItem[] {
    return JSON.parse(JSON.stringify(cart.lineItems));
  }

  public getSerials(cart: Cart, findBy: LineItemFindByParam): TrackDetail[] {
    const serialProduct = this.getLineItem(cart, findBy);
    return serialProduct.trackDetails;
  }
  protected getLineItemIndex(
    lineItems: LineItem[] | ExtraLineItem[],
    findBy: LineItemFindByParam,
  ): number {
    if (
      !findBy.id &&
      !findBy.variantId &&
      !findBy.sku &&
      !findBy.productId &&
      (findBy.index == null ||
        findBy.index < 0 ||
        findBy.index >= lineItems.length)
    ) {
      throw new InvalidSearchParameters(findBy);
    }
    if (findBy.index != null) {
      return findBy.index;
    }
    const index: number = lineItems.findIndex(
      (lineItem: LineItem | ExtraLineItem): boolean =>
        (findBy.id != null && lineItem.id === findBy.id) ||
        (findBy.variantId != null && lineItem.variantId === findBy.variantId) ||
        (findBy.productId != null && lineItem.productId === findBy.productId) ||
        (findBy.sku != null && lineItem.sku === findBy.sku),
    );
    if (index < 0) throw new ProductNotFoundInCart(findBy);
    return index;
  }

  protected calculateCart(cart: Cart): Cart {
    const newCart = this.resetCart(cart, cart);

    const { lineItems: lineItemsWithPromo, promotionGroups } =
      newCart.applyPromotions
        ? this.applyPromotionsToLineItems(newCart.lineItems)
        : newCart;

    if (lineItemsWithPromo.find((item) => item.promotionDetails.length)) {
      /**
       * reset invoice discount if cart has promotion items
       */
      newCart.discount = {
        unitAmount: 0,
        amountType: AmountTypeConstant.Percentage,
        rate: 0,
        type: DiscountTypeConstant.Invoice,
        total: 0,
        totalWithTax: 0,
        name: '',
      };
    }
    const lineItems: LineItem[] = this.calculateLineItems(
      lineItemsWithPromo,
      newCart.discount,
      cart.priceMode,
      cart.taxMode,
    );

    const cartSubtotal = this.calculateCartSubtotal(
      lineItems,
      TaxType.Exclusive,
    );
    const cartSubtotalWithTax = this.calculateCartSubtotal(
      lineItems,
      TaxType.Inclusive,
    );

    const invoiceDiscount = this.calculateDiscount(
      newCart.discount,
      cartSubtotal,
      cartSubtotalWithTax,
      1,
    );

    const { amountSummary, discountSummary, taxSummary, promotionSummary } =
      this.calculateSummaries(lineItems, invoiceDiscount);

    const updatedCart: Cart = {
      ...newCart,
      lineItems,
      discount: invoiceDiscount,
      amountSummary,
      discountSummary,
      taxSummary,
      promotionSummary,
      promotionGroups,
    };

    updatedCart.validationErrors =
      cartValidationService.validateCart(updatedCart);

    return updatedCart;
  }

  // --------------------------------- MAPPERS, CALCULATORS ETC ----------------------------------

  private mapCartLineItemToPromotionLineItem(
    cartLineItem: CartTypes.LineItem,
    index: number,
  ): PromotionTypes.LineItem {
    return {
      lineItemId: index,
      categoryIds: cartLineItem.categoryIds,
      price: cartLineItem.priceTaxInclusive.base,
      priceExclusive: cartLineItem.priceTaxExclusive.base,
      promotion: cartLineItem.promotions[0],
      quantity: cartLineItem.quantity,
      taxType: TaxType.Exclusive,
      variantId: cartLineItem.variantId,
    };
  }

  private calculateLineItemTax(
    tax: LineItemTax,
    amount: number,
    totalWithoutTax: number,
    quantity: number,
  ): LineItemTax {
    let totalUnitTax = 0;
    let totalTax = 0;

    const compoundTax = tax.taxLines.find(
      (taxLine) => taxLine.type === TaxMethodConstant.Compound,
    );

    // first calculate compound tax
    if (compoundTax) {
      const minimumTax = compoundTax.minAmount || 0;
      const unitAmount = Math.max(
        this.calculateTax(amount, compoundTax.rate),
        minimumTax,
      );

      const total = this.multiply(unitAmount, quantity);

      compoundTax.unitAmount = unitAmount;
      compoundTax.total = total;
      totalUnitTax = unitAmount;
      totalTax = total;
    }

    const totalWithCompoundTax = this.add(totalTax, totalWithoutTax);
    const unitPriceWithCompoundTax = this.add(totalUnitTax, amount);

    // calculate simple tax
    const simpleTaxLines = tax.taxLines
      .filter((taxLine) => taxLine.type === TaxMethodConstant.Simple)
      .map((taxLine) => {
        const unitAmount = this.calculateTax(
          unitPriceWithCompoundTax,
          taxLine.rate,
        );
        const total = this.calculateTax(totalWithCompoundTax, taxLine.rate);

        totalUnitTax = this.add(unitAmount, totalUnitTax);
        totalTax = this.add(totalTax, total);

        return {
          ...taxLine,
          unitAmount,
          total,
        };
      });

    const taxLines = compoundTax
      ? [compoundTax, ...simpleTaxLines]
      : simpleTaxLines;

    return {
      ...tax,
      taxLines,
      unitAmount: totalUnitTax,
      total: totalTax,
    };
  }

  private applyPromotionsToLineItems(lineItems: LineItem[]): {
    lineItems: LineItem[];
    promotionGroups: PromotionTypes.PromotionGroup[];
  } {
    const promotionLineItems = promotionService.getUniqueLineItems(
      lineItems.map(
        (lineItem, index): PromotionTypes.LineItem =>
          this.mapCartLineItemToPromotionLineItem(lineItem, index),
      ),
    );
    const invoicePromotions: InvoicePromotions =
      promotionEngineService.applyPromotionsToLineItems(promotionLineItems);
    const promoLineItems = invoicePromotions.lineItemPromotions;
    const promotionGroups = invoicePromotions.promotionGroups;
    const newLineItems = lineItems?.map((lineItem, index): LineItem => {
      const promotionObject = invoicePromotions.promotions.find(
        (promo): boolean =>
          promo.id ===
          promotionGroups.find((pg) =>
            pg.yLineItemQuantities.find((yliq) => yliq.lineItemId === index),
          )?.promotionId,
      );
      let promotion = promoLineItems.find(
        (item): boolean => item.promotionDetails.id === promotionObject?.id,
      );
      const lineItemPromoQuantity =
        promotionEngineService.getYPromotionQuantity(index, promotionGroups);
      if (!promotion) {
        // Simple Promo
        promotion = promoLineItems.find(
          (item): boolean => item.lineItem.variantId === lineItem.variantId,
        );
      }
      if (
        !promotion ||
        (lineItemPromoQuantity === 0 &&
          promotion?.promotionDetails.type !==
            PromotionTypes.PromotionType.Simple)
      ) {
        promotion = undefined;
      }

      return {
        ...lineItem,
        promotionType: promotionObject?.type,
        promotionDetails: promotion
          ? [
              {
                ...promotion.promotionDetails,
                applicableQuantity:
                  lineItemPromoQuantity ||
                  promotion.promotionDetails.applicableQuantity,
              },
            ]
          : [],
      };
    });

    return {
      lineItems: newLineItems,
      promotionGroups,
    };
  }

  /**
   * @param tax
   * @param price
   * @param quantity
   * @returns the tax inclusive price
   */
  protected getTaxInclusivePrice(
    tax: Tax | TaxLine | LineItemTax | LineItemTaxLine,
    price: number,
    quantity = 1,
  ): number {
    return this.calculateTaxInclusivePrice(price, tax.rate, quantity);
  }

  private calculateLineItems(
    lineItems: LineItem[],
    invoiceDiscount: Discount,
    priceMode: InvoiceEnums.PriceMode,
    taxMode: TaxType,
  ): LineItem[] {
    const cartSubtotal = this.calculateCartSubtotal(
      lineItems,
      TaxType.Exclusive,
    );

    return lineItems.map((lineItem, index): LineItem => {
      const base = lineItem.priceTaxExclusive.base;
      const baseWithTax = lineItem.priceTaxInclusive.base;
      const subtotal = this.multiply(lineItem.quantity, base);
      const subtotalWithTax = this.calculateTaxInclusivePrice(
        subtotal,
        lineItem.tax.rate,
      );
      let unitFinalPrice = base;

      const discounts: Discount[] = [];

      let lineItemTotalPromotion = 0;

      let lineItemUnitPromotion = 0;

      let promotionQuantity = 0;

      if (priceMode === InvoiceEnums.PriceModeConstant.Retail) {
        lineItem.promotionDetails?.forEach((promotion: PromotionDetails) => {
          promotionQuantity += Math.min(
            promotion.applicableQuantity,
            lineItem.quantity,
          );
          const discount = this.calculateDiscount(
            {
              id: promotion.id,
              name: promotion.name,
              rate:
                promotion.discountType === PromotionDiscountType.Percentage
                  ? this.divide(promotion.amount, 100)
                  : 1,
              amountType:
                promotion.discountType === PromotionDiscountType.Amount
                  ? AmountTypeConstant.Fixed
                  : AmountTypeConstant.Percentage,
              type: DiscountTypeConstant.Promotion,
              unitAmount:
                promotion.discountType === PromotionDiscountType.Amount &&
                taxMode === TaxType.Inclusive
                  ? this.calculateTaxExclusiveFromTaxInclusive(
                      promotion.amount,
                      lineItem.tax.rate,
                    )
                  : promotion.amount,
              total: 0,
              totalWithTax: 0,
            },
            base,
            baseWithTax,
            promotionQuantity,
          );
          lineItemUnitPromotion = this.add(
            discount.unitAmount,
            lineItemUnitPromotion,
          );
          lineItemTotalPromotion = this.add(
            discount.total,
            lineItemTotalPromotion,
          );
          unitFinalPrice = this.subtract(unitFinalPrice, discount.unitAmount);
          discounts.push(discount);
        });
      }

      // calculate line-item discounts
      let lineItemTotalDiscount = 0;
      lineItem.discounts.forEach((discount) => {
        if (discount.type === DiscountTypeConstant.Product) {
          // calculates discount on base amount because promo and prod discount cannot exist together
          const calculatedDiscount = this.calculateDiscount(
            discount,
            base,
            baseWithTax,
            lineItem.quantity,
          );
          discounts.push(calculatedDiscount);
          lineItemTotalDiscount = this.add(
            lineItemTotalDiscount,
            calculatedDiscount.total,
          );
          unitFinalPrice = this.subtract(
            unitFinalPrice,
            calculatedDiscount.unitAmount,
          );
        }
      });

      const extras = this.calculateExtras(
        lineItem.extras,
        invoiceDiscount,
        cartSubtotal,
      );
      const extrasSubtotal = extras.reduce(
        (total, extra): number => total + extra.subtotal,
        0,
      );
      const extrasTotal = extras.reduce(
        (total, extra): number => total + extra.subtotalWithTax,
        0,
      );

      const unitFinalPriceWithExtras = this.add(unitFinalPrice, extrasSubtotal);

      // adding invoice discount on line item level
      let rate;
      if (
        invoiceDiscount.type === DiscountTypeConstant.Invoice &&
        invoiceDiscount.amountType === AmountTypeConstant.Fixed
      ) {
        rate = cartSubtotal
          ? this.divide(invoiceDiscount.unitAmount, cartSubtotal)
          : 0;
      } else {
        rate = invoiceDiscount.rate;
      }
      if (rate > 0) {
        const lineItemInvoiceDiscount = this.calculateDiscount(
          {
            ...invoiceDiscount,
            rate,
            amountType: AmountTypeConstant.Percentage,
          },
          unitFinalPriceWithExtras,
          this.calculateTaxInclusivePrice(
            unitFinalPriceWithExtras,
            lineItem.tax.rate,
          ),
          lineItem.quantity,
        );
        discounts.push({
          ...lineItemInvoiceDiscount,
          amountType: invoiceDiscount.amountType,
        });
      }

      const priceTaxExclusive: LineItemPrice = {
        ...lineItem.priceTaxExclusive,
        final: unitFinalPrice,
        extrasTotal: extrasSubtotal,
      };

      const totalWithoutTax = this.calculateTotalWithoutTax(
        priceTaxExclusive,
        lineItem.quantity,
        lineItemTotalPromotion,
        lineItemTotalDiscount,
      );

      const tax: LineItemTax = this.calculateLineItemTax(
        lineItem.tax,
        unitFinalPriceWithExtras,
        totalWithoutTax,
        lineItem.quantity,
      );

      const totalTax = tax.total;
      const priceTaxInclusive: LineItemPrice = {
        base: this.getTaxInclusivePrice(tax, priceTaxExclusive.base),
        retail: this.getTaxInclusivePrice(tax, priceTaxExclusive.retail),
        wholesale: this.getTaxInclusivePrice(tax, priceTaxExclusive.wholesale),
        final: this.getTaxInclusivePrice(tax, priceTaxExclusive.final),
        extrasTotal,
      };

      const total = this.add(totalWithoutTax, totalTax);

      const calculatedLineItem: LineItem = {
        ...lineItem,
        totalDiscount: this.add(lineItemTotalDiscount, lineItemTotalPromotion),
        subtotalWithTax: this.add(
          subtotalWithTax,
          this.multiply(extrasTotal, lineItem.quantity),
        ),
        priceTaxExclusive,
        priceTaxInclusive,
        discounts,
        tax,
        totalTax,
        subtotal: this.add(
          subtotal,
          this.multiply(extrasSubtotal, lineItem.quantity),
        ),
        totalWithoutTax,
        total,
        extras,
        index,
      };

      return calculatedLineItem;
    });
  }

  private calculateExtras(
    extras: ExtraLineItem[],
    invoiceDiscount: Discount,
    cartSubtotal: number,
  ): ExtraLineItem[] {
    return extras.map((extra): ExtraLineItem => {
      const base = extra.priceTaxExclusive.base;
      const subtotal = this.multiply(extra.quantity, base);
      const subtotalWithTax = this.calculateTaxInclusivePrice(
        base,
        extra.tax.rate,
        extra.quantity,
      );
      const unitFinalPrice = base;

      const discounts: Discount[] = [];

      // adding invoice discount on line item level
      let rate;
      if (
        invoiceDiscount.type === DiscountTypeConstant.Invoice &&
        invoiceDiscount.amountType === AmountTypeConstant.Fixed
      ) {
        rate = cartSubtotal
          ? this.divide(invoiceDiscount.unitAmount, cartSubtotal)
          : 0;
      } else {
        rate = invoiceDiscount.rate;
      }
      if (rate > 0) {
        const lineItemInvoiceDiscount = this.calculateDiscount(
          {
            ...invoiceDiscount,
            rate,
            amountType: AmountTypeConstant.Percentage,
          },
          base,
          extra.priceTaxInclusive.base,
          extra.quantity,
        );
        discounts.push({
          ...lineItemInvoiceDiscount,
          amountType: invoiceDiscount.amountType,
        });
      }

      const priceTaxExclusive: LineItemPrice = {
        ...extra.priceTaxExclusive,
        final: unitFinalPrice,
      };

      const totalWithoutTax = this.calculateTotalWithoutTax(
        priceTaxExclusive,
        extra.quantity,
        0,
        0,
      );

      const tax: LineItemTax = this.calculateLineItemTax(
        extra.tax,
        unitFinalPrice,
        totalWithoutTax,
        extra.quantity,
      );
      const totalTax = tax.total;

      const priceTaxInclusive: LineItemPrice = {
        base: this.getTaxInclusivePrice(tax, priceTaxExclusive.base),
        retail: this.getTaxInclusivePrice(tax, priceTaxExclusive.retail),
        wholesale: this.getTaxInclusivePrice(tax, priceTaxExclusive.wholesale),
        final: this.getTaxInclusivePrice(tax, priceTaxExclusive.final),
        extrasTotal: 0,
      };

      const total = this.add(totalWithoutTax, totalTax);

      const calculatedLineItem: ExtraLineItem = {
        ...extra,
        totalDiscount: 0,
        priceTaxExclusive,
        priceTaxInclusive,
        discounts,
        tax,
        totalTax,
        subtotal,
        subtotalWithTax,
        totalWithoutTax,
        total,
      };

      return calculatedLineItem;
    });
  }

  private calculateDiscount(
    discount: Discount,
    price: number,
    priceWithTax: number,
    quantity: number,
  ): Discount {
    const discountAmount = Math.max(
      0,
      Math.min(
        this.calculateDiscountAmount(
          price,
          discount.amountType === AmountTypeConstant.Percentage
            ? discount.rate
            : discount.unitAmount,
          discount.amountType,
        ),
        price,
      ),
    );
    let rate: number;
    if (discount.amountType === AmountTypeConstant.Percentage) {
      rate = discount.rate;
    } else {
      rate = price ? this.divide(discount.unitAmount, price) : 0;
    }
    const total = this.multiply(discountAmount, quantity);
    const totalWithTax = this.calculateDiscountAmount(
      priceWithTax,
      rate,
      AmountTypeConstant.Percentage,
      quantity,
    );
    return {
      ...discount,
      rate,
      unitAmount: discountAmount,
      total,
      totalWithTax,
    };
  }

  private calculateSummaries(
    lineItems: LineItem[],
    invoiceDiscount: Discount,
  ): {
    taxSummary: TaxSummary;
    discountSummary: DiscountSummary;
    amountSummary: AmountSummary;
    promotionSummary: PromotionSummary;
  } {
    const promotionSummary: Discount[] = [];
    const taxSummary: TaxSummary = [];
    const discountSummary: DiscountSummary = {
      [DiscountTypeConstant.Invoice]: 0,
      [DiscountTypeConstant.Product]: 0,
      [DiscountTypeConstant.Promotion]: 0,
    };

    const amountSummary: AmountSummary = {
      totalDiscounts: 0,
      totalTax: 0,
      subtotal: 0,
      total: 0,
      totalWithoutTax: 0,
      subtotalWithTax: 0,
    };

    for (let index = 0; index < lineItems.length; index++) {
      const lineItem = lineItems[index];
      amountSummary.subtotal = this.add(
        amountSummary.subtotal,
        lineItem.subtotal,
      );
      amountSummary.subtotalWithTax = this.add(
        amountSummary.subtotalWithTax,
        lineItem.subtotalWithTax,
      );
      amountSummary.total = this.add(amountSummary.total, lineItem.total);
      amountSummary.totalWithoutTax = this.add(
        amountSummary.totalWithoutTax,
        lineItem.totalWithoutTax,
      );

      // tax summary
      const lineItemTax = lineItem.tax;
      const tax: TaxBreakdown | undefined = taxSummary.find(
        (tax): boolean => lineItemTax.id === tax.id,
      );
      if (tax) {
        tax.total = this.add(tax.total, lineItemTax.total);
        tax.taxableAmount = this.add(
          tax.taxableAmount,
          lineItem.totalWithoutTax,
        );
        for (
          let taxLineIndex = 0;
          taxLineIndex < tax.taxLines.length;
          taxLineIndex++
        ) {
          const lineItemTaxLine = lineItemTax.taxLines[taxLineIndex];
          const taxLine: TaxLineBreakdown = tax.taxLines[taxLineIndex];
          if (taxLine && lineItemTaxLine) {
            taxLine.total = this.add(taxLine.total, lineItemTaxLine.total);
          }
        }
      } else {
        const taxLines = lineItemTax.taxLines.map(
          (taxLine: TaxLineBreakdown): TaxLineBreakdown => {
            const taxLineItem: TaxLineBreakdown = {
              total: taxLine.total,
              id: taxLine.id,
              name: taxLine.name,
              rate: taxLine.rate,
              taxId: taxLine.taxId,
              type: taxLine.type,
              minAmount: taxLine.minAmount,
            };
            return taxLineItem;
          },
        );
        taxSummary.push({
          total: lineItemTax.total,
          id: lineItemTax.id,
          name: lineItemTax.name,
          rate: lineItemTax.rate,
          code: lineItemTax.code,
          taxableAmount: lineItem.totalWithoutTax,
          taxLines,
        });
      }

      // sort tax summary so we have compound taxes first
      taxSummary.sort(this.sortByCompoundFirst);

      // discount and promotion summary
      for (let i = 0; i < lineItem.discounts.length; i++) {
        const discount = lineItem.discounts[i];
        discountSummary[discount.type] = this.add(
          discountSummary[discount.type],
          discount.total,
        );
        if (discount.type === DiscountTypeConstant.Promotion) {
          const promotion: Discount | undefined = promotionSummary.find(
            (promotion): boolean => promotion.id === discount.id,
          );
          if (promotion) {
            const totalPromotionAppliedTo = this.add(
              this.divide(promotion.total, promotion.rate),
              this.divide(discount.total, discount.rate),
            );
            promotion.total = this.add(promotion.total, discount.total);
            promotion.totalWithTax = this.add(
              promotion.totalWithTax,
              discount.totalWithTax,
            );
            promotion.rate = this.divide(
              promotion.total,
              totalPromotionAppliedTo,
            );
          } else {
            promotionSummary.push({ ...discount });
          }
        }
      }
    }

    // adjusting invoice level discount in summaries
    for (
      let taxSummaryIndex = 0;
      taxSummaryIndex < taxSummary.length;
      taxSummaryIndex++
    ) {
      const tax = taxSummary[taxSummaryIndex];
      tax.total = this.calculateDiscountedPrice(
        tax.total,
        invoiceDiscount.rate,
        AmountTypeConstant.Percentage,
      );
      tax.taxableAmount = this.calculateDiscountedPrice(
        tax.taxableAmount,
        invoiceDiscount.rate,
        AmountTypeConstant.Percentage,
      );
      amountSummary.totalTax = this.add(amountSummary.totalTax, tax.total);
      for (
        let taxLineIndex = 0;
        taxLineIndex < tax.taxLines.length;
        taxLineIndex++
      ) {
        const taxLine: TaxLineBreakdown = tax.taxLines[taxLineIndex];
        taxLine.total = this.calculateDiscountedPrice(
          taxLine.total,
          invoiceDiscount.rate,
          AmountTypeConstant.Percentage,
        );
      }
    }
    amountSummary.total = this.calculateDiscountedPrice(
      amountSummary.total,
      invoiceDiscount.rate,
      AmountTypeConstant.Percentage,
    );
    amountSummary.totalWithoutTax = this.calculateDiscountedPrice(
      amountSummary.totalWithoutTax,
      invoiceDiscount.rate,
      AmountTypeConstant.Percentage,
    );
    amountSummary.totalDiscounts = Object.values(discountSummary).reduce(
      (total: number, discount: number): number => discount + total,
    );

    return {
      taxSummary,
      discountSummary,
      amountSummary,
      promotionSummary,
    };
  }

  private sortByCompoundFirst = (a: Tax, b: Tax) => {
    const aIsCompound = a.taxLines.some(
      (taxLine) => taxLine.type === TaxMethodConstant.Compound,
    );
    const bIsCompound = b.taxLines.some(
      (taxLine) => taxLine.type === TaxMethodConstant.Compound,
    );
    return aIsCompound === bIsCompound ? 0 : aIsCompound ? -1 : 1;
  };

  protected mapTaxToLineItemTax(tax: Tax, price: number): LineItemTax {
    const unitAmount = this.calculateTax(price, tax.rate);
    const lineItemTax: LineItemTax = {
      ...tax,
      unitAmount,
      total: unitAmount,
      taxLines: tax.taxLines?.map((taxLine): LineItemTaxLine => {
        const unitAmount = this.calculateTax(price, tax.rate);
        const lineItemTaxLine: LineItemTaxLine = {
          ...taxLine,
          unitAmount,
          total: unitAmount,
        };
        return lineItemTaxLine;
      }),
    };
    return lineItemTax;
  }

  private calculateCartSubtotal(
    lineItems: LineItem[],
    taxMode: TaxType,
  ): number {
    return lineItems.reduce((total, lineItem) => {
      const price =
        taxMode === TaxType.Inclusive
          ? lineItem.priceTaxInclusive
          : lineItem.priceTaxExclusive;
      const unitTotalWithExtras = this.add(price.base, price.extrasTotal);
      const lineItemSubtotal = this.multiply(
        unitTotalWithExtras,
        lineItem.quantity,
      );
      return this.add(total, lineItemSubtotal);
    }, 0);
  }

  /**
   *
   * @param price tax exclusive price
   * @param totalPromotions
   * @param totalDiscounts
   * @returns the total without tax for a line item. This total has discounts and promotions applied but does not have the tax.
   */
  private calculateTotalWithoutTax(
    price: LineItemPrice,
    quantity: number,
    totalPromotions: number,
    totalDiscounts: number,
  ): number {
    // unit price with no promotion and no discount
    const unitPriceWithExtra = this.add(price.base, price.extrasTotal);
    // total price with no promotion and no discount
    const totalPriceWithExtras = this.multiply(unitPriceWithExtra, quantity);
    // total price with promotion and discount
    return this.subtract(
      this.subtract(totalPriceWithExtras, totalPromotions),
      totalDiscounts,
    );
  }
}
