import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { InputText } from 'primeng/inputtext';
import { LocalStorageKey } from '../../../shared/constants';
import { LocalStorageService } from '../../../core/services/local-storage.service';

@Component({
  selector: 'rw-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements AfterViewInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();

  @Input() required:boolean;

  @Input() label: string;

  @Input() helpText: string;

  @Input() isRTL:boolean;

  @Input() type:string;

  @Input() context:string = '';

  @Output() onReset: EventEmitter<void> = new EventEmitter();

  @ViewChild(InputText, { static: false }) InputTextComponentRef: InputText;

  displayResetButton: boolean;

  constructor(
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {}

  ngAfterViewInit(): void {
    this.isRTL = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    ) === 'ar';
    if (!this.control || !this.InputTextComponentRef) return;
    if (this.control.value) {
      this.displayResetButton = true;
      this.InputTextComponentRef.filled = true;
    } else {
      this.displayResetButton = false;
      this.InputTextComponentRef.filled = false;
    }
    this.control.valueChanges.subscribe((v) => {
      if (v) {
        this.displayResetButton = true;
        this.InputTextComponentRef.filled = true;
      } else {
        this.displayResetButton = false;
        this.InputTextComponentRef.filled = false;
      }
    });
    this.InputTextComponentRef.ngDoCheck();
    this.cd.detectChanges();
  }

  reset() {
    this.control.setValue(null);
    this.onReset.emit();
    this.InputTextComponentRef.filled = false;
    this.InputTextComponentRef.ngDoCheck();
  }
}
