import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import { Intercom } from 'ng-intercom';
import { ScriptService } from 'ngx-script-loader';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Userpilot } from 'userpilot';
import { BootInput } from 'ng-intercom/src/app/ng-intercom/types/boot-input';
import { environment } from '../environments/environment';
import { AuthUser } from './auth/auth.models';
import { UserService } from './auth/services/user.service';
import { GeoLocationService } from './services/geo-location.service';
import { AppService } from './applications/services/app.service';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from './shared/decorators/auto-unsubscribe';
import { Merchant } from './shared/model/Merchant';
import { LocalStorageKey, companyTypeToSector } from './shared/constants';
import { SpinnerService } from './shared/services/spinner.service';
import { LocalStorageService } from './core/services/local-storage.service';
import { CobrowsingService } from './core/services/cobrowsing.service';
import { CobrowsingModalComponent } from './core/components/cobrowsing-modal/cobrowsing-modal.component';
import { AuthService } from './auth/services/auth.service';
import { LoginInProcessService } from './auth/services/login-in-process.service';

declare let pendo: any;
declare let dataLayer: any;
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rw-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe
export class AppComponent implements OnInit, AfterViewInit, AutoUnsubscribeI {
  subscriptionRefs;

  lng: 'ar' | 'en';

  isUpdateGeoLocationInProgress = false;

  private cobrowsingDialogRef: DynamicDialogRef | undefined;

  constructor(
    private cobrowsingService: CobrowsingService,
    private dialogservice: DialogService,
    public spinnerService: SpinnerService,
    private router: Router,
    private titleService: Title,
    private appService: AppService,
    private loginInProcessService: LoginInProcessService,
    public intercom: Intercom,
    private primengConfig: PrimeNGConfig,
    private scriptService: ScriptService,
    public geoLocationService: GeoLocationService,
    private userService: UserService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    if (environment.applicationUrl === 'https://platform.rewaatech.com') {
      const temp = document.createElement('meta');
      temp.innerHTML = `<meta property="og:title" content="رواء | نظام متقدم يساعد المحلات التجارية على إدارة المخزون والمبيعات - رِواء">
    <meta property="og:description" content="مع منصة رواء بإمكان التاجر إدارة المخزون، المبيعات، الفواتير والمشتريات من شاشة واحدة">
    <meta property="og:url" content="https://www.rewaatech.com/">
    <meta property="og:image" content="https://www.rewaatech.com/wp-content/uploads/2021/09/Arabic-MIMS-Photo.svg">
    <meta property="og:locale" content="ar_AR">
    <meta name="description" content="مع منصة رواء بإمكان التاجر إدارة المخزون، المبيعات، الفواتير والمشتريات من شاشة واحدة">`;
      const { head } = document;
      while (temp.firstChild) {
        head.appendChild(temp.firstChild);
      }
    } else {
      const temp = document.createElement('meta');
      temp.innerHTML =
        '<meta name="robots" content="noindex"> <meta name="googlebot" content="noindex">';
      const { head } = document;
      while (temp.firstChild) {
        head.appendChild(temp.firstChild);
      }
    }
    try {
      const user = this.localStorageService.getItem<AuthUser>(
        LocalStorageKey.User,
      );
      if (user) {
        this.userService.setUser(user);
      }
    } catch (error) {
      console.error(error);
    }

    if (!this.localStorageService.getItem<string>(LocalStorageKey.Language)) {
      this.localStorageService.setItem<string>(LocalStorageKey.Language, 'ar');
      this.lng = 'ar';
      this.titleService.setTitle('منصة رواء');
    } else {
      const lang = this.localStorageService.getItem<'ar' | 'en'>(
        LocalStorageKey.Language,
      );
      this.lng = lang;
      if (this.lng === 'ar') {
        this.titleService.setTitle('منصة رواء');
      } else {
        this.titleService.setTitle('Rewaa Platform');
      }
    }
    this.subscriptionRefs = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.loginInProcessService.loginInProcess) return;
          this.spinnerService.startLoading();
          return;
        }
        if (event instanceof NavigationEnd) {
          if (environment.enableUserPilot) Userpilot.reload();
        }
        this.spinnerService.stopLoading();
      });
    this.handleDatadog();
    this.handlePendo();
  }

  keyboardShortcutDialogRef: DynamicDialogRef;

  ngAfterViewInit(): void {
    if (
      this.localStorageService.getItem<string>(LocalStorageKey.Language) ===
      'ar'
    ) {
      window.document.body.classList.add('rtl');
      window.document.body.classList.add('arabic-font');
    } else {
      window.document.body.classList.add('ltr');
      window.document.body.classList.add('english-font');
    }
  }

  ngOnInit(): void {
    if (window.location !== window.parent.location) {
      window.parent.location.href = window.location.href;
    }
    this.setReturnUrl();
    this.handleIntercom();
    this.handleUserPilot();
    this.handleUserguiding();
    this.initiateRippleEffect();
    this.handleGoogleAnalytics();
    this.handleFullStory();
    this.checkCurrentLocation();
    this.checkCobrowsingDialogStatus();
  }

  setReturnUrl(): void {
    if (this.authService.needsRefresh())
      this.localStorageService.setItem<string>(
        LocalStorageKey.returnUrl,
        window.location.pathname + window.location.search,
      );
  }

  private checkCobrowsingDialogStatus(): void {
    this.cobrowsingService.onCobrowsingDialogToggled.subscribe(
      (dialogVisible) => {
        if (dialogVisible) {
          this.cobrowsingDialogRef = this.dialogservice.open(
            CobrowsingModalComponent,
            {
              maskStyleClass: 'blurred-background',
              closable: false,
              closeOnEscape: false,
            },
          );
        }
        if (!dialogVisible && this.cobrowsingDialogRef) {
          this.cobrowsingDialogRef.close();
        }
      },
    );
  }

  handleGoogleAnalytics() {
    if (environment.enableGoogleAnalytics) {
      this.userService
        .getUser()
        .pipe(
          filter((user) => !!user),
          distinctUntilChanged(),
          debounceTime(1000),
          tap((user) => {
            const found = dataLayer.find(
              (e) => e.event === 'userData' && e.user_id === user?.id,
            );
            if (!found) {
              dataLayer.push({
                event: 'userData',
                user_id: user.id,
              });
            }
          }),
        )
        .subscribe();
    }
  }

  async handleFullStory() {
    this.userService
      .getUser()
      .pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        tap(async (user) => {
          if (user) {
            if (!FullStory.isInitialized()) {
              FullStory.init({
                orgId: 'o-1EF6QP-na1',
                devMode: !environment.enableFullStory,
              });
            }
            FullStory.identify(
              `${user.id}`,
              await this.getUserInfoForFullStoryAndUserPilot(user),
            );
            this.removeReturnUrl();
          }
        }),
      )
      .subscribe();
  }

  removeReturnUrl(): void {
    this.localStorageService.removeItem(LocalStorageKey.returnUrl);
  }
  async getInstalledApps(): Promise<object> {
    const apps = await firstValueFrom(
      this.appService.getSubscribedAppsApiCall(),
    );
    const appsObject = {};
    apps.forEach((app) => {
      appsObject[`${app.name.toLowerCase().split(' ').join('')}_bool`] = true;
    });
    return appsObject;
  }

  async getUserInfoForFullStoryAndUserPilot(user: AuthUser): Promise<object> {
    const installedApps = await this.getInstalledApps();
    return {
      displayName_str: user.name,
      email_str: user.email,
      role_str: user?.SystemRole,
      plan_str:
        user?.RewaaAccountSubscription?.plan?.name ||
        user?.RewaaAccountSubscription?.planId,
      subscriptionStartDate_date: user?.RewaaAccountSubscription?.startDate,
      subscriptionEndDate_date: user?.RewaaAccountSubscription?.endDate,
      companyType_str: user?.companyType,
      city_str: user?.locations?.[0]?.city || user?.companyAddress,
      locations_int: user?.locations?.length,
      status: this.getUserStatus(user),
      schema_name: user?.schemaName,
      lastSignIn_date: new Date(),
      sector: companyTypeToSector[user?.companyType],
      fixedBusinessSize_str: user?.businessSize,
      businessSizeLastUpdatedOn_date: user?.businessSizeLastUpdatedOn,
      installedApps,
    };
  }

  getUserStatus(user: AuthUser): 'expired' | 'active' | 'in-trial' | 'no-sub' {
    if (!user?.RewaaAccountSubscription) {
      return 'no-sub';
    }
    const trialPlan = user?.RewaaAccountSubscription?.trialPlan;
    const endDate = new Date(user?.RewaaAccountSubscription?.endDate);
    if (Date.now() <= endDate.valueOf()) {
      if (trialPlan) {
        return 'in-trial';
      }
      return 'active';
    }
    return 'expired';
  }

  handlePendo() {
    if (environment.enablePendo) {
      console.log('Enabling Pendo');
      this.userService
        .getUser()
        .pipe(
          filter((user) => !!user),
          distinctUntilChanged(),
          tap((user) => {
            pendo.initialize({
              visitor: {
                id: `${user.id}`,
                email: user.email,
                role: user?.SystemRole,
              },
              account: {
                id: user.schemaName,
                isPaying: this.isUserPaying(user),
              },
            });
          }),
        )
        .subscribe();
    }
  }

  isUserPaying(user: Merchant) {
    const startDate = new Date(user.RewaaAccountSubscription?.startDate);
    const endDate = new Date(user.RewaaAccountSubscription?.endDate);
    return (
      !user.RewaaAccountSubscription?.trialPlan &&
      startDate?.valueOf() < Date.now() &&
      endDate?.valueOf() > Date.now()
    );
  }

  handleDatadog() {
    if (environment.enableDatadog) {
      this.userService
        .getUser()
        .pipe(
          filter((user) => !!user),
          distinctUntilChanged(),
          tap((user) => {
            datadogRum.setUser({
              id: `${user.id}`,
              email: user.email,
              plan: user.RewaaAccountSubscription,
            });
          }),
        )
        .subscribe();
    }
  }

  handleIntercom() {
    this.userService
      .getUser()
      .pipe(
        tap((user) => {
          if (environment.enableIntercom) {
            const alignment = this.lng === 'ar' ? 'right' : 'left';
            let config: BootInput = {
              app_id: environment.intercomAppId,
              alignment,
            };
            if (user) {
              config = {
                email: user.email,
                user_id: `${user.id}`,
                user_hash: user.intercomUserHash,
                created_at: new Date(user.createdAt).valueOf(),
                // custom fields need to be in quotes
                'company name': user.companyName,
                'phone number': user.phone,
                website: user.website,
                'business type': user.companyType,
                ...config,
              };
            }
            this.intercom.boot(config);
          }
        }),
        distinctUntilChanged(),
        tap((user) => {
          if (environment.enableIntercom) {
            if (user) {
              this.intercom.update({
                app_id: environment.intercomAppId,
                email: user.email,
                user_id: `${user.id}`,
                user_hash: user.intercomUserHash,
                created_at: new Date(user.createdAt).valueOf(),
                // custom fields need to be in quotes
                'company name': user.companyName,
                'phone number': user.phone,
                website: user.website,
                'business type': user.companyType,
              });
            } else {
              this.intercom.shutdown();
              const alignment = this.lng === 'ar' ? 'right' : 'left';
              this.intercom.boot({
                app_id: environment.intercomAppId,
                alignment,
              });
            }
          }
        }),
      )
      .subscribe();
  }

  handleUserPilot() {
    if (environment.enableUserPilot) {
      this.userService
        .getUser()
        .pipe(
          distinctUntilChanged(),
          debounceTime(1000),
          tap(async (user: AuthUser) => {
            if (user) {
              Userpilot.identify(
                `${user.id}`,
                await this.getUserInfoForFullStoryAndUserPilot(user),
              );
            } else {
              console.info('User Not Found - error pushing to userPilot');
            }
          }),
        )
        .subscribe();
    }
  }

  handleUserguiding() {
    if (environment.enableUserGuiding) {
      this.scriptService
        .loadScript('assets/scripts/userguiding.js')
        .pipe(
          switchMap(() =>
            this.userService.getUser().pipe(
              filter((user) => !!user),
              distinctUntilChanged(),
            ),
          ),
        )
        .subscribe((user) => {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          window['userGuiding'].identify(user.email, {
            email: user.email,
            name: user.companyName,
            created_at: new Date().getTime(),
            role: user?.SystemRole,
            plan:
              user?.RewaaAccountSubscription?.plan?.name ||
              user?.RewaaAccountSubscription?.planId,
            subscriptionStartDate: user?.RewaaAccountSubscription?.startDate,
            subscriptionEndDate: user?.RewaaAccountSubscription?.endDate,
            companyType: user?.companyType,
            city: user?.locations?.[0]?.city || user?.companyAddress,
            locations: user?.locations?.length,
            status: this.getUserStatus(user),
          });
        });
    }
  }

  isMobile = () => window.innerWidth <= 992;

  isTabletLandscape = () => window.innerWidth <= 1024;

  initiateRippleEffect(): void {
    this.primengConfig.ripple = true;
  }

  checkCurrentLocation() {
    this.userService
      .getUser()
      .pipe(
        filter((user) => !!user),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude } = position.coords;
            const { longitude } = position.coords;
            this.updateLocation(latitude, longitude);
          });
        }),
      )
      .subscribe();
  }

  updateLocation(latitude: number, longitude: number) {
    const body = {
      latitude,
      longitude,
    };
    // send new location to backend
    if (!this.isUpdateGeoLocationInProgress) {
      this.isUpdateGeoLocationInProgress = true;
      return this.geoLocationService
        .setGeoLocation(body)
        .pipe(
          take(1),
          tap(() => {
            this.isUpdateGeoLocationInProgress = false;
          }),
        )
        .subscribe();
    }
    return of(true);
  }
}
