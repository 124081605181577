export enum TrackingEventName {
  /** @deprecated */
  OfflineInvoiceCreated = 'OfflineInvoiceCreated',
  /** @deprecated */
  OfflineInvoiceSynced = 'OfflineInvoiceSynced',

  PosSaleInvoiceCreated = 'PosSaleInvoiceCreated',
  PosSaleInvoiceCreationFailed = 'PosSaleInvoiceCreationFailed',
  PosSaleInvoiceSynced = 'PosSaleInvoiceSynced',
  PosSaleInvoiceSyncingFailed = 'PosSaleInvoiceSyncingFailed',
  PosOfflineInvoicesSyncCompleted = 'PosOfflineInvoicesSyncCompleted',
  KeyboardShortcutTriggered = 'KeyboardShortcutTriggered',
  PosOfflineSaleInvoiceSyncCompleted = 'PosOfflineSaleInvoiceSyncCompleted',
}

export enum PosSaleInvoiceMode {
  Offline = 'Offline',
  Online = 'Online',
  OfflineFirst = 'OfflineFirst',
}

export enum PerformanceMarkTag {
  PosSellStarted = 'pos:sell:started',
  PosSellInvoked = 'pos:sell:invoked',
  PosSellBrowserPrintReady = 'pos:sell:browserPrint:ready',
  PosSellPluginPrintInvoked = 'pos:sell:pluginPrint:invoked',
}
