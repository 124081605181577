import { Component, Input } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';

@Component({
  selector: 'rw-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent {
  @Input() changeLang: string;

  constructor(
    private authService: AuthService,
  ) { }

  switchLang(lang) {
    if (lang === 'ar') {
      this.changeLang = 'en';
      localStorage.setItem('language', 'ar');
    } else if (lang === 'en') {
      this.changeLang = 'ar';
      localStorage.setItem('language', 'en');
    }
    this.authService.switchLanguage(lang);
  }
}
