<!-- Auto Login Loader -->
<div *ngIf="(loginInProcessService.loginInProcessScreen$ | async ) " class="loading-background">
  <div>
    <div class="logo" >
      <img class="dark_logo" src="../../../../assets/icons/Rewaa-dark-logo.svg" />
      <img class="dark_logo_animated" src="../../../../assets/icons/Rewaa-dark-logo.svg" />
      <img class="full_logo" src="../../../../assets/icons/Rewaa-full-logo.svg" />
    </div>

    <div class="text-container">
      <div class="text" id="text1">{{'autoLogin.welcome' | translate}}</div>
      <div class="text" id="text2">{{'autoLogin.settingUp' | translate}}</div>
      <div class="text" id="text3">{{'autoLogin.technicalPartner' | translate}}</div>
    </div>
  </div>
</div>

<rw-app-spinner *ngIf="isShowSpinner"></rw-app-spinner>
<div *ngIf="!smsOtpFeatureFlag" class="rw__content d-flex align-items-center justify-content-center h-100">
  <div class="flex flex-column rw__content-body w-full">
    <section class="flex flex-column bg-white rounded-8 shadow-1 rw__p-9">
      <form
        [formGroup]="fGroup"
        #ngForm="ngForm"
        class="flex flex-column"
      >
        <div class="d-flex align-items-center justify-content-between rw__mb-6">
          <div class="d-flex align-items-center">
            <button
              pButton
              pRipple
              type="button"
              [icon]="lang === 'ar' ? 'fa-regular fa-arrow-right' : 'fa-regular fa-arrow-left'"
              class="bg-grey-20 rw__button rw__button-xs rw__button-icon rounded-circle p-button-text p-button-plain rw__me-5"
              (click)="navigateToRegister()"
            >
            </button>
            <img src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="Rewaa" height="42" />
          </div>
          <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
        </div>
        <div class="d-flex flex-column rw__mb-5">
          <label class="fw-500 rw__fs-6 rw__mb-0">{{ 'Enter the code' | translate }}</label>
          <div *ngIf="email.value" class="d-flex align-items-center rw__mt-4">
            <span class="rw__fs-9">{{ 'Enter the 6-digit code sent to' | translate }} {{ email.value }}</span>
            <button
              pButton
              pRipple
              type="button"
              icon="fa-regular fa-pen"
              class="rw__button rw__button-xs rw__button-icon rounded-circle p-button-text p-button-plain rw__ms-2"
              (click)="navigateToRegister()"
            >
            </button>
          </div>
        </div>
        <div class="d-flex flex-column">
          <span class="rw__input rw__input-icon rw__input-float p-float-label w-full" [ngClass]="{'rw__input-icon-close': fGroup.get('code').value}">
            <input id="verification-code-input" pInputText pendo-tracker="cri" pInputText formControlName="code" type="text" id="code" required />
            <label for="verification-code-input">
              <i class="text-danger">*</i>
              {{ 'Verification Code' | translate }}
            </label>
            <i *ngIf="fGroup.get('code').value" class="fa-regular fa-xmark" (click)="fGroup.get('code').reset()"></i>
          </span>
          <div *ngIf="(code.touched && code.invalid) || fGroup.get('code').errors?.cannotContainSpace" class="d-flex rw__mt-2">
            <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
            <small class="p-error block mx-1">{{ 'Code is required' | translate }}</small>
          </div>
          <div *ngIf="errMessage && code.valid" class="d-flex rw__mt-2">
            <i class="fa-regular fa-circle-exclamation rw__mt-1 p-error"></i>
            <small class="p-error block mx-1">{{ errMessage | translate }}</small>
          </div>
        </div>
        <div class="d-flex flex-md-row flex-column-reverse flex-wrap align-items-center justify-content-between rw__mt-6">
          <button pButton type="button" class="rw__button p-button-text p-button-primary rw__mt-4 w-full w-md-auto" label="{{'auth.resendCodeTitle' | translate}}"
            (click)="resendConfirmationV1(email.value)" [disabled]=""></button>
          <button pButton type="submit" class="rw__button p-button-raised p-button-primary rw__mt-4 rw__ms-md-5 w-full w-md-auto"
            label="{{'auth.verifyYourAccountTitle' | translate}}" (click)="confirmV1(fGroup.value)"
            [disabled]="fGroup.invalid || isShowSpinner"></button>
        </div>
      </form>
    </section>
  </div>
</div>

<div *ngIf="smsOtpFeatureFlag" class="auth-page min-h-screen align-items-center justify-content-center md:flex w-full flex-row-reverse"  [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  <rw-side-panel></rw-side-panel>

  <div class="auth-form-area flex align-items-center bg-white justify-content-center w-full mx-0 md:mx-3">
    <div class="py-5 px-3">
      <div class="flex align-items-center justify-content-between gap-2 md:gap-5 pb-5">
        <img class="max-w-full h-auto d-block auth-logo" src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="">
        <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      <form
        [formGroup]="fGroup"
        #ngForm
        class="p-fluid grid auth-form"
      >
        <div class="field col-12 rw__pb-0">
          <label class="rw__fs-6 fw-500 rw__mb-3">{{'auth.verifyYourAccountTitle' | translate}}</label>
          <p class="rw__fs-9 text-grey-60 rw__mb-3" [innerHTML]="message | translate : {medium: medium}"></p>
          <p class="rw__fs-9 text-grey-60 rw__mb-3" [innerHTML]="translationPrefix+'.enterCodeToVerify' | translate"><strong>{{ medium }}</strong></p>
        </div>
        <div class="field col-12 rw__mb-5">
          <div class="rw__otp rw__otp-w-full">
            <rw-otp-input [formControl]="otpFormControl" [otpLength]="6" (getOTP)="getOTP($event)" [autoFocusInput]="true"></rw-otp-input>
          </div>
            <rw-input-error *ngIf="errMessage"
            [text]="errMessage | translate" class="block rw__mt-2"></rw-input-error>
        </div>
        <div class="field col-12 rw__my-2 d-flex align-items-center justify-content-between w-full">
          <button pButton type="button" class="rw__button p-button-text w-auto rw__px-0 bg-transparent" label="{{'auth.resendCodeTitle' | translate}}"
            (click)="resendConfirmation(email.value)" pendo-tracker="crb" [disabled]="timerVisible"></button>
          <span *ngIf="timerVisible" class="rw__fs-9 text-grey-60 block" [innerHTML]="'resetTimer' | translate : {countDownSeconds: countdownSeconds}" ></span>
        </div>       
        <div class="field col-12 rw__mb-0 text-center">
          <button pendo-tracker="crb-1" pButton pRipple
            type="submit"
            class="login-button rw__button rw__mb-3"
            label="{{'auth.verifyYourAccountTitle' | translate}}"
            (click)="confirm(fGroup.value)"
            [disabled]="isShowSpinner || !vButtonVisibility"
          >
          </button>
          <button pButton pRipple
            type="button"
            class="p-button-text bg-white rw__button rw__button-hide-default-label w-full d-flex justify-content-center"
            (click)="navigateToRegister()"
          ><i class="rw__me-4" [ngClass]="lang === 'ar' ? 'fa-regular fa-arrow-right' : 'fa-regular fa-arrow-left'"></i>{{ 'auth.goBack' | translate }}
          </button>
          <hr class="rw__mb-4" />
          <span class="rw__my-3">
            {{ 'Need Help?' | translate}} 
            <button  class="rw__button text-primary border-0 bg-transparent p-button-text" (click)="startIntercom()">{{
              'Contact Support' | translate
            }}</button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
