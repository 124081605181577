import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  interval,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { LocalStorageKey } from '../../shared/constants';
import { LocalStorageService } from '../../core/services/local-storage.service';

@Injectable()
export class TimerService {
  private timerActive$ = new BehaviorSubject<boolean>(false);

  private countdownSeconds$ = new BehaviorSubject<number>(0);

  private countdownInterval$ = interval(1000);

  private destroy$ = new Subject<void>();

  constructor(private localStorageService: LocalStorageService) {
    this.checkForTimer();
  }

  public checkForTimer(): void {
    const timerStartTime = this.localStorageService.getItem<number>(
      LocalStorageKey.resendTimer,
    );

    const timeLeft = 25 - (Math.floor(Date.now() / 1000) - timerStartTime);
    if (timeLeft < 0) {
      this.localStorageService.removeItem(LocalStorageKey.resendTimer);
    } else if (timerStartTime) {
      this.timerActive$.next(true);
      this.resumeTimer(25);
    }
  }

  public startTimer(seconds: number): void {
    this.setStartTimeOnLocalStorage();
    this.resumeTimer(seconds);
  }

  public resumeTimer(seconds: number): void {
    this.countdownSeconds$.next(seconds);
    this.countdownInterval$
      .pipe(
        takeWhile(() => this.countdownSeconds$.value > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.countdownSeconds$.next(
          seconds -
            (Math.floor(Date.now() / 1000) -
              this.localStorageService.getItem<number>(
                LocalStorageKey.resendTimer,
              )),
        );
        if (this.countdownSeconds$.value <= 0) {
          this.stopCountdown();
        }
      });
  }

  setStartTimeOnLocalStorage(): void {
    if (
      !this.localStorageService.getItem<number>(LocalStorageKey.resendTimer)
    ) {
      this.localStorageService.setItem<number>(
        LocalStorageKey.resendTimer,
        Math.floor(Date.now() / 1000),
      );
      this.timerActive$.next(true);

      setTimeout(async () => {
        this.localStorageService.removeItem(LocalStorageKey.resendTimer);
        this.timerActive$.next(false);
      }, 25000);
    }
  }

  private stopCountdown(): void {
    this.timerActive$.next(false);
    this.localStorageService.removeItem(LocalStorageKey.resendTimer);
    this.destroy$.next();
    this.destroy$.complete();
  }

  isTimerActive(): Observable<boolean> {
    return this.timerActive$.asObservable();
  }

  currentTimeRemaining(): Observable<number> {
    return this.countdownSeconds$.asObservable();
  }
}
