import { Invoice } from '../../../shared/model/invoice/Invoice';
import { PAYMENT_STATUSES, INVOICE_TYPES } from '../../../shared/constants';
import { InvoicesCalculator } from '../../utilities/invoices.calculator';
import { CreditableDebitable } from '../model/creditable-debitable';

function capitalizePaymentStatus(paymentStatus: string): string {
  return paymentStatus
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const mapInvoiceToCreditable = (
  invoice: Invoice,
): CreditableDebitable => ({
  id: invoice.id,
  invoiceNumber: invoice.invoiceNumber,
  status: invoice.PayableInvoice.paymentStatus,
  total: invoice.totalTaxInclusive,
  totalPaid: invoice.totalPaidAmount
    ? invoice.totalPaidAmount
    : (invoice.totalPaidAmount = 0),
  credit: InvoicesCalculator.subtract(
    invoice.totalTaxInclusive,
    invoice.totalPaidAmount,
  ),
  amount: 0,
  statusAfterPayment: invoice.PayableInvoice.paymentStatus,
  totalPaidAfterPayment: invoice.totalPaidAmount ? invoice.totalPaidAmount : 0,
  creditAfterPayment: InvoicesCalculator.subtract(
    invoice.totalTaxInclusive,
    invoice.totalPaidAmount,
  ),
  uri: `/invoices/purchase-order/${invoice.invoiceNumber}`,
  min: 0,
  max: invoice.totalTaxInclusive - invoice.totalPaidAmount,
  notes: invoice.notes,
  createdAt: invoice.createdAt,
  stockLocationName: invoice.stockLocationName,
  supplierInvoiceNumber: invoice.PayableInvoice.supplierInvoiceNumber,
});

export const mapInvoiceToDebitable = (invoice) => {
  let totalPaid;
  let debit;
  if (invoice.type === INVOICE_TYPES.POS_SALE) {
    totalPaid = InvoicesCalculator.add(
      invoice.PayableInvoice.paidAmount,
      invoice.totalPaidAmount ? invoice.totalPaidAmount : 0,
    );
    debit = InvoicesCalculator.subtract(
      invoice.PayableInvoice.debitAmount,
      invoice.totalPaidAmount,
    );
  } else {
    totalPaid = invoice.totalPaidAmount ? invoice.totalPaidAmount : 0;
    debit = InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.totalPaidAmount,
    );
  }

  return {
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber,
    status: invoice.PayableInvoice.paymentStatus,
    total: invoice.totalTaxInclusive,
    totalPaid,
    debit,
    totalPaidAmount: invoice.remainingBalance,
    amount: 0,
    showAmount: (0).toFixed(2),
    statusAfterPayment: invoice.PayableInvoice.paymentStatus,
    totalPaidAfterPayment: invoice.totalPaidAmount
      ? invoice.totalPaidAmount
      : 0,
    debitAfterPayment: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.totalPaidAmount,
    ),
    uri: `/invoices/return-stocks/${invoice.invoiceNumber}`,
    min: 0,
    max: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.totalPaidAmount,
    ),
  };
};

export const mapInvoiceToRtnDebitable = (
  invoice: Invoice,
  statusAfterPayment,
): CreditableDebitable => ({
  id: invoice.id,
  invoiceNumber: invoice.invoiceNumber,
  status:
    invoice.totalPaidAmount === 0
      ? PAYMENT_STATUSES.NOT_PAID_DEBTOR
      : PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR,
  total: invoice.totalTaxInclusive,
  totalPaid: invoice.totalPaidAmount ? invoice.totalPaidAmount : 0,
  debit: InvoicesCalculator.subtract(
    invoice.totalTaxInclusive,
    invoice.totalPaidAmount,
  ),
  amount:
    invoice.PayableInvoice.PaymentToPayableInvoices.returnInvoicePaidAmount,
  statusAfterPayment,
  totalPaidAfterPayment: InvoicesCalculator.add(
    invoice.totalPaidAmount,
    invoice.PayableInvoice.PaymentToPayableInvoices.returnInvoicePaidAmount,
  ),
  debitAfterPayment: InvoicesCalculator.subtract(
    invoice.totalTaxInclusive,
    InvoicesCalculator.add(
      invoice.totalPaidAmount,
      invoice.PayableInvoice.PaymentToPayableInvoices.returnInvoicePaidAmount,
    ),
  ),
  uri: `/invoices/return-stocks/${invoice.invoiceNumber}`,
  min: 0,
  max: 0,
});

export const mapInvoiceToPOCreditable = (
  invoice: Invoice,
  statusAfterPayment,
): CreditableDebitable => {
  const totalPaidAfterPayment = InvoicesCalculator.add(
    invoice.totalPaidAmount,
    invoice.PayableInvoice.PaymentToPayableInvoices.Payment.paidAmount,
  );
  return {
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber,
    status: capitalizePaymentStatus(invoice.PayableInvoice.paymentStatus),
    total: invoice.totalTaxInclusive,
    totalPaid: invoice.totalPaidAmount ? invoice.totalPaidAmount : 0,
    credit: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.totalPaidAmount,
    ),
    amount: invoice.PayableInvoice.PaymentToPayableInvoices.Payment.paidAmount,
    statusAfterPayment,
    totalPaidAfterPayment,
    creditAfterPayment: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      totalPaidAfterPayment,
    ),
    uri: `/invoices/purchase-order/${invoice.invoiceNumber}/view`,
    min: 0,
    max: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.totalPaidAmount,
    ),
  };
};

export const mapInvoiceForReturnStock = (invoice): unknown => {
  const totalPaid = invoice.paidAmount ? invoice.paidAmount : 0;
  const debit = InvoicesCalculator.subtract(
    invoice.totalTaxInclusive,
    invoice.paidAmount,
  );

  return {
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber,
    status: capitalizePaymentStatus(invoice.paymentStatus),
    total: invoice.totalTaxInclusive,
    totalPaid,
    debit,
    amount: 0,
    showAmount: (0).toFixed(2),
    totalPaidAmount: totalPaid,
    statusAfterPayment: capitalizePaymentStatus(invoice.paymentStatus),
    totalPaidAfterPayment: 0,
    debitAfterPayment: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.paidAmount,
    ),
    uri: `/invoices/return-stocks/${invoice.invoiceNumber}`,
    min: 0,
    max: InvoicesCalculator.subtract(
      invoice.totalTaxInclusive,
      invoice.paidAmount,
    ),
  };
};

export const mapInvoiceForReturnDebit = (
  stockOrder,
  statusAfterPayment,
  payments,
) => {
  const findPaymentLine = payments?.paymentLines?.find(
    (i) => i.stockOrderId === stockOrder.id,
  );
  return {
    invoiceNumber: stockOrder?.invoiceNumber,
    total: stockOrder?.totalTaxInclusive,
    totalPaid: findPaymentLine.amount ? findPaymentLine.amount : 0,
    amount: findPaymentLine.amount,
    debit: stockOrder?.remainingBalance,
    status:
      findPaymentLine?.paidAmount === 0
        ? PAYMENT_STATUSES.NOT_PAID_DEBTOR
        : PAYMENT_STATUSES.PARTIALLY_PAID_DEBTOR,
    totalPaidAfterPayment: InvoicesCalculator.add(
      stockOrder.totalPaidAmount,
      findPaymentLine.amount,
    ),
    statusAfterPayment,
    debitAfterPayment: InvoicesCalculator.subtract(
      stockOrder.totalTaxInclusive,
      findPaymentLine.amount,
    ),
    uri: `/invoices/return-stocks/${stockOrder.invoiceNumber}`,
    min: 0,
    max: 0,
  };
};

export const mapInvoiceForReturnPayment = (
  payments,
  totalTaxInclusive,
): unknown => ({
  invoiceNumber: payments?.invoiceNumber,
  status: 'Completed',
  createdAt: payments?.createdAt,
  PayableInvoice: {
    paymentMethod: payments.paymentLines[0].paymentMethodName,
    totalBeforePayment: totalTaxInclusive,
    totalAfterPayment: InvoicesCalculator.subtract(
      totalTaxInclusive,
      payments.amount,
    ),
  },
  totalPaidAmount: payments.amount,
  supplierName: payments.suppliers.name,
  notes: payments.notes,
});