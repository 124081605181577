import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmRegistrationComponent } from './components/confirm/confirm-registration.component';
import { ResetPasswordComponent } from './components/forget-password/reset-password/reset-password.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { TrialSubscribeUserComponent } from './components/trial-subscribe-user/trial-subscribe-user.component';

// Services
import { UnAuthenticatedGuardService } from './services/unauthenticated-auth.guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'confirmation',
    component: ConfirmRegistrationComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  {
    path: 'activate-user/:activatedCode',
    component: ActivateUserComponent,
    canActivate: [UnAuthenticatedGuardService],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
