import {
  FiscalPeriodStatus,
  FiscalPeriodTimePeriod,
} from '../constants/fiscal-period-constants';
import { FiscalPeriod } from '../internals/types/mims-service-types';

type TimePeriod = { startDate: Date; endDate: Date };

export function checkFiscalPeriodClash(
  fiscalPeriod: TimePeriod,
  existingFiscalPeriods: TimePeriod[],
): boolean {
  return existingFiscalPeriods.some(
    ({ startDate, endDate }) =>
      fiscalPeriod.startDate <= endDate && fiscalPeriod.endDate >= startDate,
  );
}

export function filterFiscalPeriods(
  existingFiscalPeriods: FiscalPeriod[],
  transactionDate: Date,
  fiscalPeriodStatus: FiscalPeriodStatus,
): boolean {
  return existingFiscalPeriods.some(
    ({ startDateTime, endDateTime, status }) =>
      transactionDate <= new Date(endDateTime) &&
      transactionDate >= new Date(startDateTime) &&
      status === fiscalPeriodStatus,
  );
}

export function convertTimePeriodToMonths(
  timePeriod: FiscalPeriodTimePeriod,
): number {
  switch (timePeriod) {
    case FiscalPeriodTimePeriod.Month:
      return 1;
    case FiscalPeriodTimePeriod.ThreeMonth:
      return 3;
    case FiscalPeriodTimePeriod.SixMonth:
      return 6;
    case FiscalPeriodTimePeriod.Year:
      return 12;
    default:
      return 0;
  }
}

export function isDateInFiscalPeriodRange(
  date: Date,
  arrayOfObjects: { startDateTime: Date; endDateTime: Date }[],
): boolean {
  for (const obj of arrayOfObjects) {
    const startDateTime = new Date(obj.startDateTime);
    const endDateTime = new Date(obj.endDateTime);

    if (date >= startDateTime && date <= endDateTime) {
      return true;
    }
  }

  return false;
}
