export const SourceConstant = {
  POS: 'POS',
  Zid: 'Zid',
  Salla: 'Salla',
  WooCommerce: 'WooCommerce',
} as const;

export type Source = (typeof SourceConstant)[keyof typeof SourceConstant];

export const SaleTypeConstant = {
  Retail: 'Retail',
  Wholesale: 'Wholesale',
} as const;

export type SaleType = (typeof SaleTypeConstant)[keyof typeof SaleTypeConstant];

export const enum PosApiErrorCode {
  InvoicesNotSynced = 'pos-invoices-not-synced',
}
export const AmountTypeConstant = {
  Fixed: 'Fixed',
  Percentage: 'Percentage',
} as const;

export type AmountType =
  (typeof AmountTypeConstant)[keyof typeof AmountTypeConstant];

export const EntityChangeEventAction = {
  CustomerCreated: 'customer-created',
  CustomerUpdated: 'customer-updated',
  CustomerDeleted: 'customer-deleted',
} as const;

export enum RegisterChangeEventActionType {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  DeActivate = 'deactivate',
  Activate = 'activate',
  SetDefault = 'set-default',
}

export enum ShiftChangeActionType {
  Open = 'open',
  Close = 'close',
  AddWithdraw = 'add-withdraw',
}