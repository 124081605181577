import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { PermissionHelperService } from '../services/permission.helper.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private permissionHelperService: PermissionHelperService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ) {
    const data = route.data.permission;
    const doesPermissionExist = this.permissionHelperService.isPermissionExist(
      data.permission,
    );
    if (!doesPermissionExist) {
      if (data.reroute) {
        const urlTree = this.router.parseUrl(data.reroute);
        return urlTree;
      }
      return false;
    }
    return true;
  }
}
