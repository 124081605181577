import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap, timer, Subscription } from 'rxjs';
import {
  AutoUnsubscribe,
  AutoUnsubscribeI,
} from 'src/app/shared/decorators/auto-unsubscribe';
import { DomSanitizer } from '@angular/platform-browser';
import { Intercom } from 'ng-intercom';
import { CustomToastService } from '../../../shared/services/custom-toast.service';
import { LoginDetails, SignupConfirmation } from '../../auth.models';
import { AuthService } from '../../services/auth.service';
import { ConfirmationCodeValidator } from './validators/confirmationCodeValidator';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { LocalStorageKey } from '../../../shared/constants';
import { MerchantService } from '../../services/merchant.service';
import { TimerService } from '../../services/timer.service';
import { UserType } from '../../../shared/types/auth.types';
import { FeatureFlagService } from '../../../shared/services/types/feature-flag.service.interface';
import { FeatureFlagEnum } from '../../../shared/constants/feature-flag.constants';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { LoginInProcessService } from '../../services/login-in-process.service';
@Component({
  selector: 'rw-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss'],
})
@AutoUnsubscribe
export class ConfirmRegistrationComponent
  implements OnInit, AutoUnsubscribeI, OnDestroy
{
  subscriptionRefs;

  fGroup: UntypedFormGroup;

  isShowSpinner = false;

  errMessage: string;

  merchantSignUpInterval$ = timer(2500, 1500);

  count = 0;

  subscription: Subscription = undefined;

  state$: Observable<Object>;

  changeLang: string;

  lang: string;

  timerVisible = false;

  unverifiedUser = false;

  vButtonVisibility = false;

  registerData: any;

  loginInProcess = false;

  message = '';

  loggedIn = false;

  countdownSeconds = 0;

  confirmationOTP = '';

  translationPrefix = 'confirmRegistration';

  medium: string;

  POLLING_LIMIT = 15;

  enterYourCodeMessage: string;

  smsOtpFeatureFlag = false;

  otpFormControl: FormControl<number | null>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private merchantService: MerchantService,
    private router: Router,
    public loginInProcessService: LoginInProcessService,
    private userAuthenticationService: UserAuthenticationService,
    private intercom: Intercom,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private toaster: CustomToastService,
    public translate: TranslateService,
    private timerService: TimerService,
    private featureFlagService: FeatureFlagService,
    private localStorageService: LocalStorageService,
  ) {
    this.timerService.checkForTimer();
    this.lang = this.localStorageService.getItem<string>(
      LocalStorageKey.Language,
    );
    translate.use(this.lang);
    if (this.lang === 'en') {
      this.changeLang = 'ar';
    } else {
      this.changeLang = 'en';
    }
    this.registerData = this.router.getCurrentNavigation()?.extras?.state;
    this.otpFormControl = new FormControl(
      null,
      Validators.compose([Validators.required]),
    );
  }

  get email() {
    return this.fGroup.get('email');
  }

  get code() {
    return this.fGroup.get('code');
  }

  startIntercom(): void {
    this.intercom.show();
  }

  private getMessageToShow(
    email: string,
    countryCode: string,
    phone: string,
    type: string,
  ) {
    if (type === UserType.Gcc) {
      this.message = `${this.translationPrefix}.phoneMsg`;
      this.medium = `${countryCode}${phone}`;
    } else if (type === UserType.GccWithoutPh) {
      this.message = `${this.translationPrefix}.phoneMsg2`;
      this.medium = phone;
    } else {
      this.message = `${this.translationPrefix}.emailMsg`;
      this.medium = email;
    }
  }

  switchLang(lang: string): void {
    this.authService.switchLanguage(lang);
  }

  ngOnDestroy(): void {
    this.loginInProcessService.stopLoginInProcessScreenLoading();
  }

  ngOnInit(): void {
    this.createForm();
    let email;
    let countryCode;
    let phone;
    let type;
    this.activatedRoute.queryParamMap.subscribe((payload) => {
      email = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('email'),
      );
      type = this.sanitizer.sanitize(SecurityContext.HTML, payload.get('type'));
      countryCode = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('cc'),
      );
      phone = this.sanitizer.sanitize(
        SecurityContext.HTML,
        payload.get('phone'),
      );
      this.fGroup.controls.email.reset({ value: email, disabled: false });
    });

    this.featureFlagService
      .isEnabled(FeatureFlagEnum.SmsOtp, undefined)
      .subscribe((smsOtpFeatureFlag) => {
        this.smsOtpFeatureFlag = smsOtpFeatureFlag;
      });

    this.getMessageToShow(email, countryCode, phone, type);

    this.timerService
      .isTimerActive()
      .pipe(
        tap((timerEnded) => {
          this.timerVisible = timerEnded;
        }),
      )
      .subscribe();

    this.timerService
      .currentTimeRemaining()
      .pipe(
        tap((timeRemaining) => {
          this.countdownSeconds = timeRemaining;
        }),
      )
      .subscribe();
  }

  createForm(): void {
    this.fGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      code: [
        '',
        [Validators.required, ConfirmationCodeValidator.cannotContainSpace],
      ],
    });
  }

  getOTP(event: string): void {
    this.confirmationOTP = event;
    this.errMessage = null;
    if (this.confirmationOTP.length === 6) {
      this.vButtonVisibility = true;
    } else {
      this.vButtonVisibility = false;
    }
  }

  checkMerchantSignUpStatus(email: string): void {
    this.subscriptionRefs = this.merchantService
      .getMerchantSignUpStatus(email)
      .subscribe(async (isSigningUp) => {
        if (!isSigningUp) {
          this.subscription.unsubscribe();
          if (this.loggedIn) return;
          this.loggedIn = true;
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          const { password } = this.registerData;
          await this.userAuthenticationService.login(
            new LoginDetails(email, password),
          );
          this.router.navigateByUrl('').finally(() => {
            this.loginInProcessService.stopLoginInProcessScreenLoading();
          });
        } else if (this.count > this.POLLING_LIMIT) {
          this.subscription.unsubscribe();
          this.router.navigate(['/login']);
        } else {
          this.count += 1;
        }
      });
  }

  async confirm(confirmation: SignupConfirmation): Promise<void> {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .confirmSignup(confirmation.email, this.confirmationOTP)
      .subscribe({
        next: async () => {
          if (!this.registerData?.password) {
            this.router.navigate(['/login']);
            return;
          }
          this.loginInProcessService.showLoginInProcessScreen();
          this.subscription = this.merchantSignUpInterval$.subscribe(() => {
            this.checkMerchantSignUpStatus(confirmation.email);
          });
        },
        error: (err) => {
          if (err.code === 'UserNotFoundException') {
            this.errMessage = 'Email or Confirmation Code is not correct.';
          } else {
            this.errMessage =
              'Confirmation Code didn’t match the code you provided';
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  resendConfirmationV1(email: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.authService
      .resendConfirmationCode(email)
      .subscribe(
        () => {
          this.isShowSpinner = false;
          this.toaster.success(
            this.translate.instant('code has been resent successfully'),
          );
        },
        (err) => {
          this.isShowSpinner = false;
          this.errMessage = err.message;
        },
      );
  }

  confirmV1(confirmation: SignupConfirmation): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.authService.confirm(confirmation).subscribe(
      () => {
        this.isShowSpinner = false;
        if (this.unverifiedUser) {
          this.router.navigate(['/reset-password'], {
            state: {
              unverifiedUser: this.unverifiedUser,
              email: confirmation.email,
            },
          });
          return;
        }
        this.router.navigate(['login']);
      },
      (err) => {
        this.isShowSpinner = false;
        if (err.code === 'UserNotFoundException') {
          this.errMessage = 'Email or Confirmation Code is not correct.';
        } else {
          this.errMessage =
            'Confirmation Code didn’t match the code sent to your mail';
        }
      },
    );
  }

  resendConfirmation(email: string): void {
    this.isShowSpinner = true;
    this.subscriptionRefs = this.merchantService
      .resendConfirmationCode(email)
      .subscribe({
        next: () => {
          this.timerService.startTimer(25);
          this.errMessage = null;
          this.toaster.success(
            this.translate.instant('code has been re-sent successfully'),
          );
        },
        error: (err) => {
          if (
            err.error?.name === 'LimitExceededException' ||
            err.error?.message === 'LimitExceededException'
          ) {
            this.toaster.error(
              this.translate.instant(
                `${this.translationPrefix}.limitExceededMessage`,
              ),
            );
          } else {
            this.errMessage = err.message;
          }
          this.isShowSpinner = false;
        },
        complete: () => {
          this.isShowSpinner = false;
        },
      });
  }

  navigateToRegister() {
    this.router.navigate(['/register'], {
      queryParams: {
        ...this.activatedRoute.snapshot.queryParams,
        registered: true,
      },
      state: { ...this.registerData },
    });
  }
}
