import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tax } from '../model/Tax';
import { TaxConfiguration, ITaxConfiguration } from '../model/TaxConfiguration';

const API_URL = '/api';

@Injectable()
export class TaxesService {
  constructor(private http: HttpClient) { }

  updateTax(taxId: number, updatedTax): Observable<Tax> {
    return this.http.put<Tax>(`${API_URL}/taxes`, updatedTax);
  }

  createTax(tax: Tax): Observable<Tax> {
    return this.http.post<Tax>(`${API_URL}/taxes`, tax);
  }

  getAllTaxes(): Observable<Tax[]> {
    return this.http.get<any[]>(`${API_URL}/taxes`);
  }

  getTaxByCode(code: string) {
    return this.http.get<Tax>(`${API_URL}/taxes/code/${code}`);
  }

  getTaxById(id: number): Observable<Tax> {
    return this.http.get<Tax>(`${API_URL}/taxes/${id}`);
  }

  getTaxConfiguration(): Observable<TaxConfiguration> {
    return this.http.get<TaxConfiguration>(`${API_URL}/taxes/configuration`);
  }

  getUserPricesConfiguration(): Observable<ITaxConfiguration> {
    let pricesConfiguration: ITaxConfiguration = {} as ITaxConfiguration;
    return this.http.get(`${API_URL}/taxes/configuration`, { observe: 'response' }).pipe(
      map((response) => {
        if (response.status === 200 && response.body) {
          const result: any = response.body;
          pricesConfiguration = {
            defaultTaxRate: result.taxId,
            sellTaxStatus: result.sellTaxation,
            costTaxStatus: result.costTaxation,
            id: result.taxId,
          };
        }
        return pricesConfiguration;
      }),
    );
  }

  saveUserPricesConfigurations(priceConfig: ITaxConfiguration): Observable<ITaxConfiguration> {
    const pricesConfiguration = {
      taxId: priceConfig.defaultTaxRate,
      sellTaxation: priceConfig.sellTaxStatus,
      costTaxation: priceConfig.costTaxStatus,
    };
    return this.http.post(`${API_URL}/taxes/configuration`, pricesConfiguration, { observe: 'response' })
      .pipe(map((response) => {
        if (response.status === 200 && response.body) {
          const result: any = response.body;
          return <ITaxConfiguration>{
            id: result.id,
            defaultTaxRate: result.taxId,
            costTaxStatus: result.costTaxation,
            sellTaxStatus: result.sellTaxation,
          };
        }
        return <ITaxConfiguration>{};
      }));
  }
}
