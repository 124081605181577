export class BaseError extends Error {
  code?: string;
  name: string;
  status: number;

  constructor(name: string, message: string, status: number, code?: string) {
    super(message);
    this.code = code;
    this.name = name;
    this.message = message;
    this.status = status;
  }
}

export class PriceLessThanCost extends BaseError {
  constructor(message: string) {
    super('PRICE LESS THAN COST', message, 422, message);
  }
}

export class BadRequestException extends BaseError {
  constructor(message: string) {
    super('Bad Request', message, 400, message);
  }
}
