import { HeaderColumnData } from '../../../ui/components/simple-table-header/types';
import { PAYMENT_STATUSES } from '../../../shared/constants';
import { PurchaseOrderRowImportType } from './types';

export const PAYMENT_STATUS_MAPPUNG_FOR_UI = {
  [PAYMENT_STATUSES.PAID]: 'Fully Paid',
  [PAYMENT_STATUSES.NOT_PAID_CREDITOR]: 'Not Paid',
  [PAYMENT_STATUSES.PARTIALLY_PAID_CREDITOR]: 'Partially Paid',
};

export const payCreditForAllPoColumns: HeaderColumnData[] = [
  {
    name: '',
    width: '3rem',
  },
  {
    name: 'Invoice Number',
  },
  {
    name: 'Payment Status',
  },
  {
    name: 'Credit Amount',
  },
  {
    name: 'Paid Amount',
    subText: 'Tax Inclusive',
  },
  {
    name: 'Invoice Credit',
    subText: 'After Payment',
  },
];

export const payCreditForRtnColumns: HeaderColumnData[] = [
  {
    name: '',
    width: '3rem',
  },
  {
    name: 'Invoice Number',
  },
  {
    name: 'Payment Status',
  },
  {
    name: 'Total Credit',
  },
  {
    name: 'Total Debit',
  },
  {
    name: 'Debit For Invoice',
    subText: 'After Payment',
  },
];

export const payCreditRowExpandData = [
  {
    label: 'Total',
    field: 'total',
  },
  {
    label: 'Total Paid',
    field: 'totalPaid',
  },
  {
    label: 'Status',
    subText: 'After Payment',
    field: 'statusAfterPayment',
  },
  {
    label: 'Total Paid',
    subText: 'After Payment',
    field: 'totalPaidAfterPayment',
  },
];

export const purchaseOrderTemplateHeaders = {
  productSKU:
    'الرمز التعريفي SKU | مطلوب | Product SKU | Required | Unique in the file and in the system | Text',
  cost: 'التكلفة الجديدة | مطلوب  | New Cost  | Required',
  qty: 'الكمية الجديدة | مطلوب | New Quantity | Required',
  tax: 'الرمز الضريبي | اختياري | Tax Code | Optional',
};

export const getDataForPurchaseOrderImportTemplate =
  (): PurchaseOrderRowImportType[] => {
    const cols = [];
    cols.push({
      [purchaseOrderTemplateHeaders.productSKU]: '',
      [purchaseOrderTemplateHeaders.cost]: '',
      [purchaseOrderTemplateHeaders.qty]: '',
      [purchaseOrderTemplateHeaders.tax]: '',
    });

    return cols;
  };

export enum InvoiceStatusEnumV2 {
  OPEN = 'open',
  COMPLETED = 'completed',
}

export enum InvoiceStatusEnumV1 {
  OPEN = 'Open',
  COMPLETED = 'Completed',
}

export enum PaymentStatusV2 {
  PAID = 'paid',
  NOT_PAID = 'not-paid',
  PARTIALLY_PAID = 'partially-paid',
  DRAFT = 'draft',
}

export enum OrderTypeV2 {
  PurchaseOrder = 'PurchaseOrder',
  ReturnStock = 'ReturnStock',
  PayCredit = 'PayCredit',
  PayPurchaseOrder = 'PayPurchaseOrder',
  ReceiveDebit = 'ReceiveDebit',
}
