<div class="auth-page min-h-screen md:flex align-items-center justify-content-center w-full flex-row-reverse" (keyup.enter)="login(fGroup.value)" [ngClass]="changeLang !== 'ar' ? 'rtl' : 'ltr'">
  
  <div class="flex-1">
    <rw-side-panel></rw-side-panel>
  </div>

  <div  class="auth-form-area flex-1 bg-white flex align-items-center justify-content-center w-full mx-0 md:mx-3">
    <div class="py-5 px-3">
      <div class="flex align-items-center justify-content-between gap-2 md:gap-5 pb-5">
        <img class="max-w-full h-auto d-block auth-logo" src="../../../../assets/images/{{changeLang !== 'ar' ? 'logo-ar.png' : 'logo-en.png'}}" alt="">
        <rw-language-switcher-v2 [changeLang]="changeLang"></rw-language-switcher-v2>
      </div>
      <form
        [formGroup]="fGroup"
        #ngForm
        (ngSubmit)="login(fGroup.value)"
        class="p-fluid grid auth-form"
      >
        <div class="field col-12 rw__pb-0">
          <label class="rw__fs-6 fw-500 rw__mb-0">{{ 'auth.signInTitle' | translate }}</label>
        </div>
        <div class="field col-12" [ngClass]="changeLang !== 'ar' ? 'rw__input-text-end' : ''">
          <span class="p-float-label" [ngClass]="changeLang === 'en' ? 'p-input-icon-left' : 'p-input-icon-right'">
            <i
              *ngIf="fGroup.get('email').value"
              aria-label="Clear"
              (click)="fGroup.get('email').patchValue()"
              class="fa-solid fa-circle-xmark fa-lg cursor-pointer"></i>
            <input pendo-tracker="li"
              formControlName="email"
              pInputText
              type="email"
              id="email"
            />
            <label>{{ 'Email' | translate }}</label>
          </span>
          <small
            *ngIf="email.touched && fGroup.get('email').hasError('required')"
            class="flex align-items-center p-error rw__mb-2"
          >
            <i class="fa-regular fa-circle-exclamation mr-1"></i>
            <span> {{ 'Email is required' | translate }} </span>
          </small>
          <small
            *ngIf="email.touched && fGroup.get('email').hasError('email')"
            class="flex align-items-center p-error"
          >
            <i class="fa-regular fa-circle-exclamation mr-1"></i>
            <span> {{ 'Not valid email' | translate }} </span>
          </small>
        </div>
        <div class="field col-12">
          <span #passwordWrapper class="p-float-label" [ngClass]="changeLang === 'en' ? 'p-input-icon-left' : 'p-input-icon-right'">
            <p-password [formControl]="fGroup.get('password')"  [toggleMask]="true" [feedback]="false" [showClear]="fGroup.get('password').value" required styleClass="p-password p-component p-inputwrapper" [styleClass]="changeLang !== 'ar' ? 'p-input-icon-left' : 'p-input-icon-right'"></p-password>
            <label #passwordLabelElement>{{ 'Password' | translate }}</label>
          </span>
          <small
            *ngIf="password.touched && password.invalid"
            class="flex align-items-center p-error"
          >
            <i class="fa-regular fa-circle-exclamation mr-1"></i>
            <span> {{ 'Password is required' | translate }} </span>
          </small>
        </div>
        <div class="field col-12 ">
          <a pendo-tracker="la" class="forgot-password-link" [routerLink]="['/reset-password']">{{
            'auth.forgotPassword' | translate
          }}</a>
        </div>
        <div class="field col-12 mb-0 text-center">
          <button pendo-tracker="lb" pButton pRipple
            type="submit"
            class="login-button rw__fs-8"
            label="{{ 'auth.login' | translate }}"
            [disabled]="fGroup.dirty && fGroup.invalid"
          >
          </button>
          <hr class="my-4" />
          <p class="text-center">{{'auth.dontHaveAnAccount' | translate}}</p>
          <button pendo-tracker="lb-1" pButton pRipple
            type="button"
            [routerLink]="['/register']"
            class="p-button-text bg-teal-10 rw__fs-8"
            label="{{ 'auth.register' | translate }}"
          >
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Setting Account Modal - Start -->
<rw-setting-account [dialogVisible]="openSettingAccountDialogVisible" (closeDialog)="closeSettingAccountDialog()"></rw-setting-account>
<!-- Setting Account Modal - End -->