export const environment = {
  name: 'dev',
  production: true,
  enablePendo: true,
  enableDatadog: false,
  enableServiceWorker: true,
  enableGoogleAnalytics: false,
  enableFullStory: false,
  applicationUrl: 'https://dev.mims.rewaatech.com',
  webSocketUrl: '',
  fullViewClientId: '7b875b58-d66b-438a-86bd-873cc9db7501',
  sallaAppClientId: '',
  zidAppClientId: '',
  bonatAppClientId: '',
  bonatRedirectUrl: '',
  cognitoAuthUrl: '',
  userPoolId: 'us-east-1_li5SzKxdF',
  clientId: '67o2dou3tnjdpqptb0v9f8m87n',
  s3InventoryBucket:
    'https://s3.amazonaws.com/mims-service-any-item-image/inventory/products/',
  defaultImage: 'default.png',
  enableHttp: true,
  enableIntercom: true,
  tapPublicKey: 'pk_test_jMgIW1ilDhXYSNPQ6BKob8aV',
  s3Bucket: 'https://s3.ap-south-1.amazonaws.com/dev.mims.rewaatech.com/',
  offline_intervals: { product: 2160000, customer: 2160000 },
  offline_config: {
    invoice_limit: { android: 250, others: 300 },
  },
  enableUserGuiding: false,
  enableUserPilot: false,
  launchDarklyClientSideId: '63566e90767c8610dc821902',
  mockLaunchDarkly: false,
  enableAutomationTestFF: false,
  automationTestFFAPIKey: '123',
  pluginUrl: 'http://localhost:5000',
  accountingApiPath: '/api/accounting',
  expenseApiPath: '/api/expense-service',
  customFieldApiPath: '/api/custom-field-service',
  firebaseConfig: {
    apiKey: 'AIzaSyDpqm3hlLPwJ5mZmyX_GuGfth1lRWB1fHo',
    authDomain: 'rewaa-test.firebaseapp.com',
    projectId: 'rewaa-test',
    storageBucket: 'rewaa-test.appspot.com',
    messagingSenderId: '181952186125',
    appId: '1:181952186125:web:d9530938a18589a82f9244',
    measurementId: 'G-6XEQD0RRJV',
  },
  intercomAppId: 'e1ig5zj5',
  enableFirestore: true,
  bonatServiceToken: '',
  allowAliasedEmails: true,
  reportDataLimit: 10000,
  reportingApiUrl: 'https://reporting.dev.mims.rewaatech.com/cubejs-api/v1',
  appVersion: require('../../package.json').version + '-dev',
  nearpayEnvironment: 'sandbox',
  launchDarklyBaseUrl: undefined,
};
