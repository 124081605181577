/* eslint-disable max-classes-per-file */
import { APP_NAMES, ProductTypes } from '../../shared/constants';
import { AppProductStatus } from '../../shared/model/AppProductStatus';
import { ProductPack } from '../../shared/model/ProductPack';
import { ProductVariantStockLocation } from '../../shared/model/ProductVariantStockLocation';
import { Brand } from './brand';
import { Category } from './category';
import { Extra } from './extra';
import { ProductStatus } from './product-status';
import { ProductVariant } from './product-variant';
import { Supplier } from './supplier';
import { Tag } from './tag';
import { VariantOption } from './variant-option';
import { UploadedImage } from "../products/components/create/product/simple-product/types";
import { ProductVariantToStockLocation } from '../products/components/create/product/variable-product/models/variable-product';

export class Product {
  public id?: number;

  public name?: string;

  public productName?: string;

  public description?: string;

  public batchTracked?: boolean;

  public availableQuantity?: number;

  public type: 'simple' | 'variable' | 'composite' | 'ecard' | 'eproduct';

  public ProductVariants?: ProductVariant[] = [];

  public VariantOptions?: VariantOption[] = [];

  public Category?: Category;

  public categoryId?: number;

  public Brand?: Brand;

  public brandId?: number;

  public Supplier?: Supplier;

  public supplierId?: number;

  public Tags?: Tag[];

  public ProductStatus?: ProductStatus;

  public createdAt?: Date;

  public updatedAt?: Date;

  public checked?: boolean;

  public selected? = false;

  public AppProductStatuses?: AppProductStatus[];

  public digitalType?: string;

  public VariantToComposites?: Array<ChildVariant> = [];

  public Files?: Array<any> = [];

  public deletedVariant?: Array<any> = [];

  public deletedPackageIds?: Array<any> = [];

  public order?: number;

  public imageInfo?: ProductImage;

  public sku?: string;

  public productImages?: UploadedImage[] | ProductImage[];

  public ProductVariantToStockLocations?: ProductVariantToStockLocation[];

  constructor(product) {
    Object.assign(this, product);
  }

  static getProductFile(files: Array<any>): Array<ProductImage> {
    const productFiles: Array<ProductImage> = new Array<ProductImage>();
    if (Array.isArray(files) && files.length > 0) {
      files.forEach((file) => {
        const productFile = file.ProductToFile;
        if (productFile) {
          productFiles.push({
            id: productFile.fileId,
            isDefault: productFile.isDefault || false,
            name: file.s3TmpKey,
            type: file.type,
            link: file.link,
          });
        }
      });
    }
    return productFiles;
  }
}

export interface IProductCreate {
  id: number;
  image: any;
  variantOption: any;
  name: string;
  sku: string;
  barcode: string;
  description: string;
  supplier: Supplier;
  type: ProductTypes;
  brand: Brand;
  category: Category;
  isBatchTracked: boolean;
  options: Array<VariantOption>;
  weightUnit: string;
  isStockDisabled: boolean;
  manageStockLevel: boolean;
  isBatchProduct: boolean;
  isWeightedScale: boolean;
  isProductPack: boolean;
  isPurchaseable: boolean;
  isSellable: boolean;
  weight: number;
  width: number;
  length: number;
  height: number;
  digitalType: string;
  mediaUrl: string;
  cards: { code: string; isSold: number; status: string }[];
  stockLocationPricing: Array<ProductVariantStockLocation>;
  extraOptions: Array<Extra>;
  variantToComposites: Array<ChildVariant>;
  productPack: Array<ProductPack>;
  defaultVariantId?: number;
  productImages: Array<{ fileId: number; isDefault: boolean }>;
  trackType: 'batch' | 'serial';
}

export class ChildVariant {
  public rate?: number;

  public ProductVariant?: ProductVariant;

  public productVariantId?: number;

  public Product?: Product;

  public productId?: number;
}

export class ProductImage {
  id?: string;

  isDefault?: boolean = false;

  public name?: string;

  public link?: string;

  public progress?: number;

  public type?: string;

  public url?: string;
}

export type PublishProduct = {
  [key: number]: { variant: boolean };
};

export type PublishProductPayload = {
  appNames: APP_NAMES[];
  products: PublishProduct;
};

export interface ProductV3Response {
  id: number;
  productId: number;
  sku: string;
  isPurchasable: boolean;
  isTracked: number;
  shippingWeight?: null;
  manageStockLevel: boolean;
  isSellable: boolean;
  isTaxable: boolean;
  isWeightedScale: boolean;
  name: string;
  barCode: string;
  description: string;
  isPackgable: boolean;
  type: string;
  variantType: string;
  trackType?: null;
  isSelected: boolean;
  createdAt: string;
  updatedAt: string;
  Product: Product;
  ProductVariantToStockLocations?:
    | ProductVariantToStockLocationsEntity[]
    | null;
  VariantToComposites?: null[] | null;
  availableQuantity: number;
  wholesalePrice: number;
  retailPrice: number;
  buyPrice: number;
  cost: number;
  taxType: string;
  publishedApps?: null[] | null;
  category: string;
  supplier: string;
  brand?: null;
  baseProductName: string;
  AppProductStatuses?: null[] | null;
}
export interface CategoryOrSupplier {
  name: string;
}
export interface ProductVariantToStockLocationsEntity {
  quantity: number;
  buyPrice: number;
  wholeSalePrice: number;
  retailPrice: number;
  cost: number;
  initialQuantity: number;
  id: number;
  StockLocation: StockLocation;
}
export interface StockLocation {
  name: string;
  id: number;
}

export type PublishProductDialogData = {
  externalAppsInstalled: string[];
  selectedProducts: ProductV3Response[];
};
